import React from 'react'
import './privacy.styles.scss'

const Privacy = () => {
    return (
        <div className="container d-flex justify-content-center flex-column mt-5 mb-5 privacy-policy">
            <div className="policy-heading text-center">INFORMATIVA PRIVACY SUL TRATTAMENTO DEI DATI PERSONALI
                PER IL SERVIZIO “SPESA A CASA”
            </div>
            <div className='d-flex justify-content-between'>
                <div className="policy-title">
                    TITOLARE DEL TRATTAMENTO
                    <div className="policy-terms">
                        CE.DI. MARCHE SOC. COOP<br />
                        VIA LEONARDO DA VINCI, 5-7<br />
                        60020 CAMERATA PICENA (AN)<br />
                        <span style={{fontFamily: 'Montserrat-Bold'}}>PEC</span>: CEDIMARCHE@PEC.CEDIMARCHE.IT<br />
                    </div>
                </div>
                <div className="policy-title">
                    RESPONSABILE PER LA PROTEZIONE DEI DATI (DPO)
                    <div className="policy-terms">
                        AVV. MASSIMILIANO GALEAZZI<br />
                        CORSO GARIBALDI, 43<br />
                        60121 ANCONA(AN)<br />
                        <span style={{fontFamily: 'Montserrat-Bold'}}>E-MAIL</span>: DPO@CEDIMARCHE.IT<br />
                    </div>
                </div>
            </div>
            <div className="policy-title">
                QUAL È LA FINALITÀ DEL TRATTAMENTO?
                <div className="policy-terms">
                    In osservanza al Regolamento Ue 2016/679 “GDPR” relativo al trattamento dei dati personali e loro libera circolazione e
                    al D.Lgs. 196/2003 e s.m.i. “Codice in materia di protezione dei dati personali”, La informiamo, in qualità di “Interessato”,
                    sulle modalità di utilizzo dei Suoi dati personali da parte della nostra Azienda, così da garantire un trattamento corretto,
                    lecito e trasparente nel rispetto dei principi posti a tutela dei Suoi diritti e libertà personali.
                    Il trattamento dei dati è finalizzato alla fornitura e alla gestione del servizio “Spesa a casa” nei Punti Vendita abilitati, che consente
                    ai clienti possessori di Carta fedeltà di effettuare la spesa dal proprio domicilio e riceverla direttamente all’indirizzo indicato o, in
                    alternativa, ritirarla presso un Punto Vendita specifico. Per poter accedere a tale servizio, è necessario registrarsi nell’apposita area
                    dedicata ed essere titolari della Carta fedeltà.
                </div>
            </div>
            <div className="policy-title">
                SU CHE BASE GIURIDICA TRATTIAMO I DATI?
                <div className="policy-terms">
                    Ai sensi dell’art. 6 GDPR, lett. b) e f) il trattamento è necessario all’esecuzione di un contratto di cui l’interessato è parte o
                    all’esecuzione di misure precontrattuali adottate su richiesta dello stesso, per ciò che attiene la realizzazione della spesa,
                    e per il perseguimento del legittimo interesse del Titolare, in riferimento alla gestione della registrazione e della piattaforma
                    presente sul sito.
                    Si specifica che, ai sensi dell’art. 6 GDPR, lett. a), il trattamento dei dati personali per finalità di marketing, riferito alla possibile
                    ricezione di comunicazioni aziendali e attività promozionali, e il trattamento di profilazione delle scelte di consumo dell’utente,
                    connesso alla ricezione di offerte e di scontistiche personalizzate, sono sottoposti specifici e appositi consensi, del tutto facoltativi
                    rispetto alla registrazione sul sito e all’attivazione della Carta fedeltà, in ogni momento modificabili.
                </div>
            </div>
            <div className="policy-title">DA CHI RACCOGLIAMO I DATI?
                <div className="policy-terms">
                    Raccogliamo sempre i dati personali direttamente dall’Interessato.
                </div>
            </div>
            <div className="policy-title">
                CHE CATEGORIE DI DATI TRATTIAMO?
                <div className="policy-terms">
                    Per le attività di trattamento sopra indicate, vengono richiesti solo dati personali comuni, quali i riferimenti anagrafici, di
                    residenza e i dati di contatto. Il conferimento dei dati personali per accedere al servizio di “Spesa a casa” è un requisito
                    necessario, per cui l’eventuale rifiuto a fornirli, in tutto o in parte, può dar luogo all’impossibilità di usufruire del servizio
                    stesso. Tali dati, nello specifico, servono per individuare il contraente, il Punto vendita più vicino dove effettuare la spesa online e per
                    permettere la consegna della spesa, oltreché che per gestire ogni comunicazione necessaria a tal fine.
                </div>
            </div>
            <div className="policy-title">
                A CHI COMUNICHIAMO I DATI PERSONALI?
                <div className="policy-terms">
                    I dati sono trattati presso la sede legale e operativa del Titolare del Trattamento ed in ogni altro luogo in cui le
                    parti coinvolte nel trattamento possono essere localizzate, ovvero i Punti vendita di riferimento. I Suoi dati
                    personali potranno essere comunicati ad enti pubblici e istituzioni preposte, al fine di rispettare gli obblighi
                    previsti dalle leggi e dai regolamenti, nonché alle aziende che forniranno i beni o i servizi richiesti dall’interessato e ch e
                    gestiranno i dati personali come titolari autonomi; così come potranno essere comunicati ad aziende esterne che svolgono
                    attività in outsourcing per nostro conto, nella loro qualità di responsabili del trattamento. Tali responsabili del trattamen to
                    dei dati sono da noi preventivamente accreditati e autorizzati a trattare i dati esclusivamente per le finalità determinate, ivi
                    compresa la corretta gestione del rapporto contrattuale instaurato tra le parti. Tutti i responsabili del trattamento sono
                    TITOLARE DEL TRATTAMENTO
                    CE.DI. MARCHE SOC. COOP
                    VIA LEONARDO DA VINCI, 5-7
                    60020 CAMERATA PICENA (AN)
                    PEC: CEDIMARCHE@PEC.CEDIMARCHE.IT
                    RESPONSABILE PER LA PROTEZIONE DEI DATI (DPO)

                    AVV. MASSIMILIANO GALEAZZI
                    CORSO GARIBALDI, 43
                    60121 ANCONA(AN)
                    E-MAIL: DPO@CEDIMARCHE.IT
                    Info Spesa a casa v.1 – Rev. 2021-02-03
                    individuati singolarmente, all’interno del nostro di sistema di gestione, e hanno ricevuto idonee istruzioni per garantire i
                    diritti degli interessati, oltre alla prescrizione dell’obbligo di rispettare il diritto di riservatezza .
                    Nello specifico, lo spazio web all’interno del
                </div>
            </div>
            <div className="policy-title">
                COME TRATTIAMO I DATI?
                <div className="policy-terms">
                    I Suoi dati personali saranno trattati dalla nostra Azienda tramite l’utilizzo di sistemi analogici e digitali.
                    Soltanto il personale autorizzato dal Titolare del Trattamento potrà accedere ai Suoi dati per effettuare le
                    operazioni di trattamento o di manutenzione dei sistemi. Adottiamo tutte le misure tecniche e organizzative utili
                    ad evitare problemi di accesso non autorizzato, divulgazione, modifica o distruzione. Queste misure sono state
                    riportate all’interno del nostro sistema di gestione privacy, che permette di mantenere un costante monitoraggio di tutela
                    nei confronti dei dati trattati, oltreché un continuo adeguamento delle procedure in base all’evolversi della realtà aziendal e.
                    Precisiamo inoltre che l’unico processo decisionale automatizzato che produce effetti giuridici, nello specifico di
                    profilazione delle abitudini e delle scelte di consumo, è quello facoltativamente e specificatamente acconsentito dai
                    sottoscrittori della Carta fedeltà in fase di registrazione, sempre comunque modificabile.
                </div>
            </div>
            <div className="policy-title">
                I DATI VENGONO TRASFERITI IN PAESI EXTRA UE?
                <div className="policy-terms">
                    I Suoi dati non saranno trasferiti in Paesi non appartenenti all’Unione Europea e/o con normative di protezione
                    dei dati personali non allineate al GDPR, nel rispetto degli artt. 44 e ss.; inoltre, non saranno oggetto da parte
                    nostra di alcuna diffusione, né di comunicazione a terzi non autorizzati o per finalità diverse da quelle riportate
                    nella presente informativa.
                </div>
            </div>
            <div className="policy-title">
                QUANTO CONSERVIAMO I DATI?
                <div className="policy-terms">
                    I dati raccolti per scopi collegati all’esecuzione di un contratto tra il Titolare e l’utente saranno trattenuti sino a
                    quando sia completata l’esecuzione di tale contratto e le relative obbligazioni. I dati personali raccolti per finalità
                    riconducibili all’interesse legittimo del Titolare saranno trattenuti sino al soddisfacimento di tale interesse.
                    Quando il trattamento è basato sul consenso dell’utente, il Titolare può conservare i dati personali più a lungo,
                    sino a quando detto consenso non venga revocato. Inoltre il Titolare potrebbe essere obbligato a conservare i dati per un
                    periodo più lungo in ottemperanza ad un obbligo di legge o per ordine di un’autorità.
                    Al termine del periodo di conservazione i dati personali saranno cancellati.
                </div>
            </div>
            <div className="policy-title">
                QUALI SONO I SUOI DIRITTI?
                <div className="policy-terms">
                    L'interessato ha diritto di chiedere al Titolare del trattamento l'accesso ai dati personali e la rettifica o la
                    cancellazione degli stessi o la limitazione dei trattamenti che lo riguardano o di opporsi al loro trattamento, oltre
                    al diritto alla portabilità dei dati, ai sensi del GDPR e, pertanto, in qualsiasi momento può richiedere una copia digitale
                    degli stessi o il trasferimento automatico ad altre aziende. Nei casi previsti, può anche opporsi o revocare il consenso
                    prestato, nei limiti previsti dagli artt. 23 GDPR, 2-undecies e 2-duodecies Codice Privacy. Resta fermo il diritto per
                    l'interessato di proporre reclamo innanzi all'Autorità Garante per la Protezione dei Dati Personali . Per esercitare tali diritti o
                    per avere informazioni sul loro contenuto, La invitiamo a inviare una richiesta via e-mail all’indirizzo del Responsabile della
                    protezione dei dati, sopra indicato.
                </div>
            </div>
            <div className="policy-title">
                In caso di cambiamenti importanti o variazioni delle finalità di trattamento, Le sarà data notizia preventiva attraverso gli
                strumenti comunicativi aziendali
            </div>
        </div>
    )
}

export default Privacy