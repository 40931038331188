import React from "react"
import { storesService } from "../../../services/basis/StoresService"
import Dropdown from "../../../components/UI/Dropdown/Dropdown";
import { Translation } from "react-i18next";

export default class CartCreateSelect extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            stores: [],
        }
    }

    componentDidMount() {
        const { zipCode, deliveryMode } = this.props;
        storesService.storesByDelivery(zipCode, deliveryMode)
            .then(data => {
                this.setState({ stores: data, });
            });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.zipCode !== nextProps.zipCode ||
            this.props.deliveryMode !== nextProps.deliveryMode) {
            storesService.storesByDelivery(nextProps.zipCode, nextProps.deliveryMode)
                .then(data => {
                    this.setState({ stores: data, });
                });
        }
    }

    render() {
        const { select, label } = this.props;
        const { stores } = this.state;
        if (stores && stores.length > 0) {
            return (<Dropdown items={stores} select={select} label={label} />);
        } else
            return (
                <Translation>
                    {t =>
                        <div className="dropdown-no-items">
                            {t("cartCreate.noStores")}
                        </div>
                    }
                </Translation>
            );
    }
}

