import React from "react"
import { Link, withRouter } from "react-router-dom";

import "./header-item.styles.scss";
import renderPrice from "../../../../utils/renderPrice";

const HeaderCartItem = (props) => {
    return (
        <div className="header-item-content">
            <Link className="link" to={props.path} onClick={props.closeAll}>
                <div className={`header-item header-item-container 
                ${(props.history.location.pathname === "/cart" ||
                        props.history.location.pathname === "/cart/create" ||
                        props.history.location.pathname === "/carts") ? "header-item-actived" : ""}`}
                    onClick={props.onClick}
                >
                    {props.items > 0 &&
                        <div className="header-item-counts">
                            <span>{props.items}</span>
                        </div>
                    }
                    {props.children}
                    {props.total && props.items > 0 &&
                        <div className="d-sm d-lg-none header-item-total">
                            <span>{renderPrice(props.total)}</span>
                        </div>
                    }
                </div>
            </Link>
            <div>
                {props.total &&
                    <div className="d-none d-lg-block header-item-label">{renderPrice(props.total)}</div>
                }
            </div>
        </div>
    );
}

export default withRouter(HeaderCartItem);