import React from "react";
import { Translation } from "react-i18next";
import { Link } from "react-router-dom"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const HomeTag = (props) => {
    return (
        <Translation>
            {t =>
                <div className="home-categories-item">
                    <div className="text-center content">
                        <Link className="home-categories-link" to={{
                            pathname: "/category/tag",
                            state: {
                                tag: props.tag, 
                                tagDescription: props.description, 
                                isTag: true
                            }
                        }}>
                            {/*<FontAwesomeIcon className="mb-2" icon={props.icon} size="2x" />*/}
                            {/*<br />*/}
                            {props.children}
                            <div className="text">{props.description}</div>
                        </Link>
                    </div>
                </div>
            }
        </Translation>
    );
}

export default HomeTag;
