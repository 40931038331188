import React from "react";
// import Logo from "../../../../images/logo-smartbip.png";
import HeaderItem from "../HeaderItems/HeaderItem";
import HeaderCartItem from "../HeaderItems/HeaderCartItem";
import config from "../../../../config/config";
import { Link, withRouter } from "react-router-dom"
import { Translation } from 'react-i18next';
import { ReactComponent as Logo } from '../../../../images/logo.svg';
import { ReactComponent as PinIcon } from '../../../../images/pin.svg';
import { ReactComponent as UserIcon } from '../../../../images/landing-user-sticky.svg';
import { ReactComponent as CartIcon } from '../../../../images/landing-cart-sticky.svg';
import { ReactComponent as CategoryIcon } from '../../../../images/category.svg';
import { ReactComponent as ShopIcon } from '../../../../images/shop.svg';

import "./new-header.styles.scss";

class NewHeader extends React.Component {
    updateCart = () => {
        if (this.props.user && config.SHOW_CART_UPDATE) {
            return (
                <Link to="/cart/update">
                    <nobr>
                        <span className="pin-link">{this.props.cart && this.props.cart.storeDescription ?
                            this.props.cart.storeDescription : ""}</span>
                    </nobr>
                </Link>
            );
        } else {
            return (
                <nobr>
                    <span className="pin-link">{this.props.cart && this.props.cart.storeDescription ?
                        this.props.cart.storeDescription : ""}</span>
                </nobr>
            );
        }
    }

    render() {
        const { user, cart, searchInput, onChange, closeAll, order, showModalLogin, submitSearch } = this.props;
        return (
            <Translation>
                {t =>
                    <div className="header">
                        <div className="header-wrapper">
                            <div className="container-fluid">
                                <div className="row header-top">
                                    <div className="col pin">
                                        {user && cart &&
                                            <>
                                                <PinIcon />
                                                {this.updateCart()}
                                            </>
                                        }
                                    </div>
                                    {user && order &&
                                        <div className="col order">
                                            <nobr><div>
                                                {t("header.yourOrder")}
                                                <Link className="order-link" to="/orders">
                                                    <span> {order && order.code} </span>
                                                </Link>
                                                {t("header.orderNumberIs")} <span className="status">{order && t(`order.status.${order.status}`)}</span>
                                            </div></nobr>
                                        </div>
                                    }
                                    <div className="col shop text-right">
                                        <Link className="link" to="/stores" role="main" aria-label="Vai ai negozi" onClick={closeAll}>
                                            <span className="shop-link">{t("stores")}</span>
                                            <ShopIcon />
                                        </Link>
                                    </div>
                                </div>
                                <div className="row header-bottom">
                                    <div className="col">
                                        <div className="align-items-center logo">
                                            <Link to="/" rel="noopener noreferrer"  aria-label="Vai alla home" onClick={closeAll}>
                                                <Logo />
                                                {/* <img src={Logo} alt="image" /> */}
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col search">
                                        <form onSubmit={e => submitSearch(e)}>
                                            <input type="search" aria-label="Cerca" onChange={onChange} value={searchInput}
                                                placeholder={t("header.mobileMobilePlaceHolder")}></input>
                                            <button type="submit" className="cta">
                                                <span>
                                                    {t("header.search")}</span>
                                            </button>
                                        </form>
                                    </div>
                                    <div className="col actions">
                                        <HeaderItem
                                            icon="product"
                                            label={t("products")}
                                            alwaysActived={false}
                                            onClick={this.props.categoriesModal}
                                            actived={this.props.showCategories}
                                            id="category"
                                        >
                                            <CategoryIcon />
                                        </HeaderItem>
                                        {user ?
                                            <HeaderItem
                                                onClick={this.props.loginModal}
                                                actived={this.props.showLogin} label={user.firstName}>
                                                <div className="user-logged">
                                                    {user.firstName.charAt(0)}{user.lastName.charAt(0)}
                                                </div>
                                            </HeaderItem>
                                            :
                                            <HeaderItem
                                                icon="user"
                                                label={t("access")}
                                                alwaysActived={false}
                                                onClick={showModalLogin}
                                                actived={this.props.showLogin}>
                                                <UserIcon />
                                            </HeaderItem>
                                        }
                                        {user &&
                                            <HeaderCartItem
                                                icon="shopping-cart"
                                                actived={this.props.showCategories}
                                                path="/cart"
                                                total={this.props.renderTotal(cart)}
                                                items={cart ? cart.itemsCount : "0"}
                                                closeAll={this.props.closeAll}>
                                                <CartIcon />
                                            </HeaderCartItem>
                                        }
                                        {!user &&
                                            <HeaderItem
                                                icon="shopping-cart"
                                                label={t("cart")}
                                                alwaysActived={false}
                                                onClick={showModalLogin}>
                                                <CartIcon />
                                            </HeaderItem>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}

export default withRouter(NewHeader);
