import React from "react"
import { Translation } from "react-i18next";
import parse from 'html-react-parser';

import "./product-info.styles.scss";

export default class ProductInfo extends React.Component {
    render() {
        const { info } = this.props;
        console.log(info);
        if (info) {
            let nutrition = null;
            if (info.nutrition) {
                try {
                    nutrition = JSON.parse(info.nutrition);
                } catch (e) {
                    nutrition = info.nutrition
                }
            }
            let ingredients = null
            if (info.ingredients) {
                try {
                    ingredients = JSON.parse(info.ingredients);
                } catch (e) {
                    ingredients = info.ingredients
                }
            }
            let allergyAdvice = null;
            let containAllergies = [];
            let canContainAllergies = [];
            let notContainAllergies = [];
            if (info.allergyAdvice) {
                try {
                    allergyAdvice = JSON.parse(info.allergyAdvice);
                    allergyAdvice.forEach(item => {
                        if (item.value.value === "Contiene") {
                            containAllergies.push(item);
                        }
                        if (item.value.value === "Non contiene") {
                            notContainAllergies.push(item);
                        }
                        if (item.value.value === "Potrebbe contenere") {
                            canContainAllergies.push(item);
                        }
                    });
                } catch (e) {
                    allergyAdvice = info.allergyAdvice;
                }
            }
            return (
                <Translation>
                    {t =>
                        <div className="product-info">
                            <div className="row">
                                {(info.description || info.country || info.storage) &&
                                    <div className="col-lg-4 col-md-6">
                                        {info.description &&
                                            <div className="mb-4">
                                                <div className="product-info-title">
                                                    {t("description")}
                                                </div>
                                                {info.description}
                                            </div>
                                        }
                                        {info.country &&
                                            <div className="mb-4">
                                                <div className="product-info-title">
                                                    {t("origin")}
                                                </div>
                                                {info.country}
                                            </div>
                                        }
                                        {info.storage &&
                                            <div className="mb-4">
                                                <div className="product-info-title">
                                                    {t("storage")}
                                                </div>
                                                {info.storage}
                                            </div>
                                        }
                                    </div>
                                }
                                {nutrition &&
                                    <div className="col-lg-3 col-md-6">
                                        <div className="mb-4">
                                            <div className="product-info-title">
                                                {t("nutrition")}
                                            </div>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th width="106px" style={{ textAlign: "right" }}>{nutrition.per100Heading}{nutrition.per100Unit && nutrition.per100Unit.toLowerCase()}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {nutrition.nutrientValues && Array.isArray(nutrition.nutrientValues) &&
                                                        nutrition.nutrientValues.map(item => (
                                                            <tr key={item.name}>
                                                                <td>{item.name}</td>
                                                                <td style={{ textAlign: "right" }}>{item.per100 && item.per100.value}</td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                }
                                {info.ingredients &&
                                    <div className="col-lg-3 col-md-6">
                                        <div className="mb-4">
                                            <div className="product-info-title">
                                                {t("ingredients")}
                                            </div>
                                            {ingredients && Array.isArray(ingredients) && ingredients.map((item, index) => (
                                                <>
                                                    <span key={index}>{parse(item.value)}</span>
                                                    {ingredients.length !== index + 1 &&
                                                        <span>, </span>
                                                    }
                                                </>
                                            ))}
                                            {ingredients && !Array.isArray(ingredients) &&
                                                <span>{ingredients}</span>
                                            }
                                        </div>
                                    </div>
                                }
                                {allergyAdvice && Array.isArray(allergyAdvice) &&
                                    <div className="col-lg-2 col-md-6">
                                        <div className="mb-4">
                                            <div className="product-info-title">
                                                {t("allergyAdvice")}
                                            </div>
                                            {containAllergies.length > 0 &&
                                                <div className="product-info-allergy">
                                                    <div className="allergy-title">
                                                        Contiene:
                                                    </div>
                                                    {containAllergies.map((item, index) => (
                                                        <>
                                                            <span key={item.name.id}>{item.name.value}</span>
                                                            {containAllergies.length !== index + 1 &&
                                                                <span>, </span>
                                                            }
                                                        </>
                                                    ))}
                                                </div>
                                            }
                                            {containAllergies.length > 0 &&
                                                <div className="product-info-allergy">
                                                    <div className="allergy-title">
                                                        Potrebbe contenere:
                                                    </div>
                                                    {canContainAllergies.map((item, index) => (
                                                        <>
                                                            <span key={item.name.id}>{item.name.value}</span>
                                                            {canContainAllergies.length !== index + 1 &&
                                                                <span>, </span>
                                                            }
                                                        </>
                                                    ))}
                                                </div>
                                            }
                                            {notContainAllergies.length > 0 &&
                                                <div className="product-info-allergy">
                                                    <div className="allergy-title">
                                                        Non contiene:
                                                    </div>
                                                    {notContainAllergies.map((item, index) => (
                                                        <>
                                                            <span key={item.name.id}>{item.name.value}</span>
                                                            {notContainAllergies.length !== index + 1 &&
                                                                <span>, </span>
                                                            }
                                                        </>
                                                    ))}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                                {allergyAdvice && !Array.isArray(allergyAdvice) &&
                                    <div className="col-lg-7 col-md-6">
                                        <div className="mb-4">
                                            <div className="product-info-title">
                                                {t("allergyAdvice")}
                                            </div>
                                            <div className="product-info-allergy">
                                                <span>{allergyAdvice}</span>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </Translation >
            );
        } else {
            return null;
        }
    }
}