import React from "react";
import HomeCarousel from "../Home/HomeCarousel/HomeCarousel"
import FeaturedProducts from "./FeaturedProducts/FeaturedProducts";
import config from "../../config/config";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import * as actionCreators from "../../redux/store/actions/index";
import HomeCategories from "./HomeCategories/HomeCategories";
import SmartBanner from 'react-smartbanner';

class Home extends React.Component {
    componentDidMount() {
        if (this.props.user) {
            this.props.current();
            if (config.ENABLED_SLIST) {
                this.props.getSlists();
                this.props.getFavorites();
            }
        }
    }

    render() {
        return (
            <Translation>
                {t =>
                    <>
                        <SmartBanner
                            title="Sì con Te - La spesa a casa"
                            author="RetAPPs Srl"
                            button="Vai"
                            daysHidden={0}
                            daysReminder={0}
                        />
                        <div className="mobile-view-search">
                            <HomeCarousel cart={this.props.cart} />
                            <HomeCategories />
                            <div className="search-stores-products">
                                <div className="text-center mt-5">
                                    <span className="title">
                                        {t("home.featuredProducts")}
                                    </span>
                                </div>
                                <FeaturedProducts />
                            </div>
                        </div>
                    </>
                }
            </Translation>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        cart: state.cart.cart,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        current: () => dispatch(actionCreators.current()),
        getFavorites: () => dispatch(actionCreators.getFavorites()),
        getSlists: () => dispatch(actionCreators.getSlists()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
