import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Form from 'react-bootstrap/Form'
import CustomInput from "../../../components/UI/Input/CustomInput";
import { Translation } from 'react-i18next';
import { Redirect } from "react-router-dom";
import { addressesService } from "../../ProfileContainer/ProfileAddresses/Service"
import Summary from "../Summary/Summary";
import { useForm } from 'react-hook-form';
import ValidateInput from "../../../components/UI/Input/ValidateInput";
// import BillingAddressSelect from "./BillingAddressSelect/BillingAddressSelect";
// import toaster from "../../../utils/toaster";
// import BillingAddressPopup from "../../ProfileContainer/ProfileAddresses/AddressPopup/BillingAddressPopup"


const UserData = (props) => {

  const { register, handleSubmit, errors, watch, setValue, triggerValidation, reset } = useForm({
    mode: 'onBlur',
  });

  const [addresses, setAddresses] = useState([]);
  const [showIntegration, setShowIntegration] = useState(false);
  const [integrationNotes, setIntegrationNotes] = useState(null);
  const { timeSlotId, invoice, selectInvoice, cart, freeze, billingAddress,
    enableProductsReplacement, enableReplacement, paymentMethod, setPaymentMethod, user } = props;

  useEffect(() => {
    triggerValidation();
    addressesService.getBillingAddresses()
      .then(response => {
        if (response.data.length > 0) {
          setAddresses(response.data)
        }
      }).catch((error) => {
        console.log(error);
      })
  }, [])

  const handleIntegrationNotes = (check) => {
    setShowIntegration(check);
    if (!check) {
      setIntegrationNotes(null)
    }
  }

  // const newAddress = (address) => {
  //   addressesService.newBillingAddress(address)
  //     .then(response => {
  //       setAddresses(response.data)
  //       toaster.standard("Indirizzo di fatturazione aggiunto con successo!")
  //     }).catch(error => {
  //       console.log(error);
  //     })
  // }

  if (!timeSlotId) {
    return <Redirect to='/checkout' />;
  }

  return (
    <Translation>
      {t =>
        <div className="checkout-data">
          <div className="checkout-data-title">{t('signup.confirmData')}</div>
          <Row className="signup-row">
            <Col className="pb-3" md={6}>
              <ValidateInput
                name="profileEmail"
                label="profileEmail"
                type="email"
                value={props.profileEmail}
                watch={watch("profileEmail")}
                onChange={props.selectProfileInfo}
                register={register({
                  required: t("required.field"),
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: t("error.invalidEmail")
                  }
                })}
              />
              <div className="form-control-invalid">
                {errors.profileEmail && errors.profileEmail.message}
              </div>
            </Col>
            <Col className="pb-3" md={6}>
              <ValidateInput
                name="profileMobilePhone"
                label="profileMobilePhone"
                type="tel"
                pattern="[0-9]"
                value={props.profileMobilePhone}
                onChange={props.selectProfileInfo}
                watch={watch("profileMobilePhone")}
                register={register({
                  required: t("required.field"),
                  pattern: {
                    value: /^\d{10}$/i,
                    message: t("error.invalidPhone")
                  }
                })}
              />
              <div className="form-control-invalid">
                {errors.profileMobilePhone && errors.profileMobilePhone.message}
              </div>
            </Col>
          </Row>
          <Row className="signup-row">
            <Col className="pb-3" md={6}>
              <ValidateInput
                name="profileFirstName"
                label="profileFirstName"
                type="text"
                pattern="[A-Za-z\s]"
                value={props.profileFirstName}
                onChange={props.selectProfileInfo}
                watch={watch("profileFirstName")}
                register={register({
                  required: t("required.field"),
                })}
              />
              <div className="form-control-invalid">
                {errors.profileFirstName && errors.profileFirstName.message}
              </div>
            </Col>
            <Col className="pb-3" md={6}>
              <ValidateInput
                name="profileLastName"
                label="profileLastName"
                type="text"
                pattern="[A-Za-z\s]"
                value={props.profileLastName}
                onChange={props.selectProfileInfo}
                watch={watch("profileLastName")}
                register={register({
                  required: t("required.field"),
                })}
              />
              <div className="form-control-invalid">
                {errors.profileLastName && errors.profileLastName.message}
              </div>
            </Col>
          </Row>
          {user?.attrib1 &&
            <Row className="signup-row">
              <Col className="pb-3">
                <ValidateInput
                  name="profileCard"
                  label="profileCard"
                  type="tel"
                  pattern="[0-9]"
                  maxLength={13}
                  value={props.profileCard}
                  onChange={props.selectProfileInfo}
                  watch={watch("profileCard")}
                  register={register({
                    pattern: {
                      value: /^02[0-9]{11}$/,
                      message: t("error.invalidCard")
                    }
                  })}
                />
                <div className="form-control-invalid">
                  {errors.profileCard && errors.profileCard.message}
                </div>
              </Col>
            </Row>
          }
          {cart && cart.deliveryMode === "AT_HOME" &&
            <Row className="signup-row">
              <Col className="pb-3">
                <CustomInput
                  label="Indirizzo di consegna"
                  disabled={true}
                  value={cart && cart.delivery && cart.delivery.addressDescription}
                  type="email"
                />
              </Col>
            </Row>
          }

          <hr />

          <div className="checkout-data-title">{t("userData.deliveryNote")}</div>

          <Form.Control
            as="textarea"
            name="notes"
            className="signup-textarea checkout-textarea w-100"
            rows="4"
            placeholder="Es. “Citofofonare al 202”, “Scala B - 3°piano”"
            onChange={props.selectProfileInfo}
            value={props.notes}
          />

          <hr />

          <div className="checkout-data-title">{t("userData.integrationNote")}</div>
          <div className="custom-control form-control-lg custom-checkbox">
            <input className="custom-control-input" type="checkbox" value="" id="integrationNote" name="integrationNote"
              onClick={(e) => { handleIntegrationNotes(e.target.checked) }}
            />
            <label className="custom-control-label" htmlFor="integrationNote">
              Abilita note integrazioni
            </label>
          </div>

          <Form.Control
            as="textarea"
            name="integrationNotes"
            id="integrationNotes"
            className="signup-textarea checkout-textarea w-100"
            rows="4"
            placeholder={t("userData.integrationNote")}
            disabled={!showIntegration}
            onChange={(e) => setIntegrationNotes(e.target.value)}
            value={integrationNotes}
          />

          <hr />

          {/* <div className="custom-control custom-checkbox">
            <input className="custom-control-input" type="checkbox" id="invoice" name="invoice"
              checked={invoice}
              onChange={selectInvoice}
            />
            <label style={{ marginBottom: "20px" }} className="custom-control-label" htmlFor="invoice">
              {t("userData.invoice")}
            </label>
          </div>

          {invoice &&
            <>
              <div className="address-container">
              <div style={{ marginBottom: "unset" }} className="checkout-data-title">{t("userData.invoiceAddress")}</div>
                <BillingAddressPopup
                  action={this.newAddress}
                  title={t("addresses.new")}
                  button={t("save")}
                  isUpdate={false}
                  trigger={
                    <span className="add-link">{t("userData.addInvoiceAddress")}</span>
                  }
                />
              </div>
              {addresses && addresses.map(address => (
                <BillingAddressSelect
                  key={address.id}
                  address={address}
                  billingAddressId={this.props.billingAddressId}
                  selectAddress={this.props.selectAddress}
                />
              ))}
            </>
          }

          <hr /> */}

          <Summary
            cart={cart}
            freeze={freeze}
            timeSlotId={timeSlotId}
            billingAddress={billingAddress}
            enableProductsReplacement={enableProductsReplacement}
            enableReplacement={enableReplacement}
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
            errors={errors}
            integrationNotes={integrationNotes}
          />

        </div>
      }
    </Translation>
  );
}

export default UserData;





