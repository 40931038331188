export const productImpression = (products) => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      ecommerce: {
        currencyCode: 'EUR',
        impressions: products && products.map((item, index) => {
          return {
            name: item.description,
            id: item.ref,
            price: item.netPrice,
            category: item.categoryId,
            position: index + 1,
            // brand: 'Google',
            // variant: 'Gray',
            // list: 'Search Results',
          }
        })
      }
    })
  }

};

export const productClick = (item) => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: 'productClick',
      ecommerce: {
        click: {
          products: [{
            name: item.description,
            id: item.ref,
            price: item.netPrice,
            category: item.categoryId,
          }]
        }
      },
      eventCallback: function () {
        console.log(item);
      }
    });
  }
};

export const addToCart = (item) => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: 'addToCart',
      ecommerce: {
        currencyCode: 'EUR',
        add: {
          products: [{
            name: item.description,
            id: item.ref,
            price: item.netPrice,
            category: item.categoryId,
            quantity: item.amount
          }]
        }
      }
    });
  }
};

export const checkout = (cart) => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: 'checkout',
      ecommerce: {
        checkout: {
          // actionField: { 'step': 1, 'option': 'Visa' },
          products: cart && cart.items && cart.items.map((item, index) => {
            return {
              name: item.description,
              id: item.ref,
              price: item.netPrice,
              category: item.categoryId,
              position: index + 1
            }
          })
        }
      },
      eventCallback: function () {
        console.log(cart);
      }
    });
  }
}

export const transaction = (order) => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      ecommerce: {
        purchase: {
          actionField: {
            id: order.cart && order.cart.payment && order.cart.payment.id,
            affiliation: order.cart && order.cart.storeDescription,
            revenue: order.cart && order.cart.totalToPay,
            shipping: order.cart && order.cart.feesTotal,
          },
          products: order.cart && order.cart.items && order.cart.items.map((item, index) => {
            return {
              name: item.description,
              id: item.ref,
              price: item.rowNetPrice,
              category: item.categoryId,
              position: index + 1
            }
          })
        }
      }
    });
  }
}

export const transactionFreeze = (cart) => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: 'purchase',
      ecommerce: {
        purchase: {
          actionField: {
            id: cart && cart.payment && cart.payment.id,
            affiliation: cart && cart.storeDescription,
            revenue: cart && cart.totalToPay,
            shipping: cart && cart.feesTotal,
          },
          products: cart && cart.items && cart.items.map((item, index) => {
            return {
              name: item.description,
              id: item.ref,
              price: item.rowNetPrice,
              category: item.categoryId,
              position: index + 1
            }
          })
        }
      }
    });
  }
}

export const signup = (item) => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: 'signup',
      profile: item,
      eventCallback: function () {
        console.log(item);
      }
    });
  }
};








