import React, { useState, useEffect } from "react";
import ValidateInput from "../../../components/UI/Input/ValidateInput";
import AddressNation from "../../../components/UI/AddressSelect/AddressNation";
import AddressCounty from "../../../components/UI/AddressSelect/AddressCounty";
import AddressCity from "../../../components/UI/AddressSelect/AddressCity";
import AddressZip from "../../../components/UI/AddressSelect/AddressZip";
import AddressDropdown from "../../../components/UI/AddressSelect/AddressDropdown";
import Loader from 'react-loader-spinner'
import { Col, Row, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Translation } from 'react-i18next';
import { connect } from "react-redux";
import { format, parseISO } from 'date-fns';
import * as actionCreators from "../../../redux/store/actions/index";

import "./profile.styles.scss";

function Profile(props) {
    const { register, handleSubmit, errors, watch, setValue } = useForm({
        mode: 'onBlur',
    });

    const address = props?.user?.address;

    const [nation, setNationValue] = useState(address ? address.nation : null);
    const [county, setCountyValue] = useState(address ? address.county : null);
    const [city, setCityValue] = useState(address ? address.city : null);
    const [nationId, setNationId] = useState(address ? address.nationId : null);
    const [countyId, setCountyId] = useState(address ? address.countyId : null);
    const [cityId, setCityId] = useState(address ? address.cityId : null);
    const [zip, setZip] = useState(address ? address.zip : null);
    const [deliveryAddress, setDeliveryAddress] = useState(address ? address.address : null);
    const [isForeign, setIsForeign] = useState();
    const [foreignNation, setForeignNation] = useState();
    const [showModal, setShowModal] = useState(false)

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        register(
            { name: "nationId" },
            { required: "Campo obbligatorio" }
        )
        register(
            { name: "countyId" },
            { required: "Campo obbligatorio" }
        )
        register(
            { name: "cityId" },
            { required: "Campo obbligatorio" }
        )
        register(
            { name: "zip" },
            { required: "Campo obbligatorio" }
        )
        register(
            { name: "address" },
            { required: "Campo obbligatorio" }
        )
        register({ name: "city" })
        register({ name: "county" })
        register({ name: "nation" })

        setValue("nationId", nationId);
        setValue("countyId", countyId);
        setValue("cityId", cityId);
        setValue("zip", zip);
        setValue("address", deliveryAddress);

        setValue("nation", nation);
        setValue("city", city);
        setValue("county", county);
    }, [register, nation, county, city, nationId, countyId, cityId, zip, deliveryAddress, setValue])

    const setNation = item => {
        setValue("nationId", item.id);
        setValue("nation", item.name);
        setNationId(item.id)
        setNationValue(item.name)
        setForeignNation(item.foreignNation);

        setCountyId(null);
        setCountyValue("");
        setCityId(null)
        setCityValue("")
        setAddress("")
        setZip("")
    }

    const setCounty = item => {
        setValue("countyId", item.id);
        setValue("county", item.name);
        setCountyId(item.id);
        setCountyValue(item.name);

        // reset also city and zip
        setCityId(null)
        setCityValue("")
        setZip("")
        setAddress("")
    }

    const setCity = item => {
        setValue("cityId", item.id);
        setCityId(item.id);
        if (item.foreignCity) {
            setValue("cityId", item.name);
            setCityId(item.name);
            setIsForeign(true)
        } else {
            setValue("cityId", item.id);
            setCityId(item.id);
            setIsForeign(false)
        }
        setValue("city", item.name);
        setCityValue(item.name)
        setAddress("")
        setZipCode("")
    }

    const setZipCode = item => {
        setValue("zip", item);
        setZip(item);
    }

    const setAddress = item => {
        setValue("address", item);
        setDeliveryAddress(item);
    }

    const onSubmit = data => {
        props.updateUser(data);
    }

    // console.log(watch())
    // console.log(watch('errors'))

    return (
        <Translation>
            {t =>
                <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off"
                    className={`needs-validation ${errors ? "had-validation" : ""}`}>
                    <Modal show={showModal} centered={true} onHide={() => setShowModal(false)} dialogClassName="profile-delete-modal">
                        <div style={{ padding: '30px' }}>
                            <div style={{ marginBottom: '1rem' }}>
                                <span style={{ fontFamily: 'Montserrat-Bold' }}>Sei sicuro di voler eliminare il profilo?</span><br /><br />
                                Il processo di eliminazione del profilo creato sul sito laspesaacasa.siconte.it non è immediato e prevede l'invio della richiesta a CE.DI.MARCHE SOC. COOP. A.R.L. che la prenderà in carico e ne darà riscontro entro 48 ore previa verifica di tutte le condizioni necessarie.<br /><br />
                                Non è ad esempio possibile eliminare automaticamente un profilo che ha una spesa in corso.<br /><br />
                                In ogni caso puoi sempre recarti in un Punto Vendita per gestire la tua richiesta.<br /><br />
                                <span style={{ fontFamily: 'Montserrat-Bold' }}>Confermi di voler procedere con la richiesta di eliminazione?</span><br />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <button
                                    className="cta"
                                    onClick={() => { setShowModal(false) }}
                                >
                                    <span>{t("cancel")}</span>
                                </button>
                                <button
                                    className="cta"
                                    onClick={() => { props.deleteUser(); setShowModal(false) }}
                                >
                                    <span>{t("confirm")}</span>
                                </button>
                            </div>
                        </div>
                    </Modal>
                    <input autoComplete="false" name="hidden" type="text" style={{ display: 'none' }} />
                    <div className="profile">
                        <p className="profile-title">{t('signup.title')}</p>
                        <div>
                            <Row className="signup-row">
                                <Col md={6} className="form-group">
                                    <ValidateInput
                                        name="firstName"
                                        label="firstName"
                                        type="text"
                                        value={props.user && props.user.firstName}
                                        watch={watch("firstName")}
                                        disabled={true}
                                        register={register({
                                            required: "Campo obbligatorio"
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.firstName && errors.firstName.message}
                                    </div>
                                </Col>
                                <Col md={6} className="form-group">
                                    <ValidateInput
                                        name="lastName"
                                        label="lastName"
                                        type="text"
                                        value={props.user && props.user.lastName}
                                        watch={watch("lastName")}
                                        disabled={true}
                                        register={register({
                                            required: "Campo obbligatorio"
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.lastName && errors.lastName.message}
                                    </div>
                                </Col>
                            </Row>
                            <Row className="signup-row">
                                <Col md={6} className="form-group">
                                    <ValidateInput
                                        name="birthDate"
                                        label="birthDate"
                                        type="text"
                                        value={props.user && props.user.birthDate &&
                                            format(parseISO(props.user.birthDate), "yyyy-MM-dd")}
                                        watch={watch("birthDate")}
                                        disabled={true}
                                        register={register({
                                            required: "Campo obbligatorio",
                                            pattern: {
                                                value: /^\d{4}-\d{2}-\d{2}$/i,
                                                message: "Data non valida"
                                            }
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.birthDate && errors.birthDate.message}
                                    </div>
                                </Col>
                                <Col md={6} className="form-group">
                                    <ValidateInput
                                        name="mobilePhone"
                                        label="mobilePhone"
                                        type="text"
                                        value={props.user && props.user.mobilePhone}
                                        watch={watch("mobilePhone")}
                                        register={register({
                                            required: "Campo obbligatorio",
                                            // pattern: {
                                            //     value: /^\d{10}$/i,
                                            //     message: "Numero di cellulare non valido" // todo i18n
                                            // }
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.mobilePhone && errors.mobilePhone.message}
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <p className="profile-subtitle">{t('signup.card')}</p>

                        <div>
                            <Row className="signup-row">
                                <Col md={6} className="form-group">
                                    <ValidateInput
                                        name="profileCard"
                                        label="profileCard"
                                        type="number"
                                        value={props.user && props.user.cards && props.user.cards[0] && props.user.cards[0].code}
                                        watch={watch("profileCard")}
                                        disabled={true}
                                        register={register
                                            // ({
                                            //     pattern: {
                                            //         value: /^0248[0-9]{9}$/,
                                            //         message: "Carta non valida" // todo i18n
                                            //     }
                                            // })
                                        }
                                    />
                                    <div className="form-control-invalid">
                                        {errors.profileCard && errors.profileCard.message}
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <p className="profile-subtitle">{t('signup.address')}</p>

                        <Row className="signup-row">
                            <Col md={4} className="form-group">
                                <AddressNation
                                    placeholder="Nazione*"
                                    setNation={setNation}
                                    value={nation}
                                />
                                <div className="form-control-invalid">
                                    {errors.nationId && errors.nationId.message}
                                </div>
                            </Col>
                            <Col md={4} className="form-group">
                                <AddressCounty
                                    placeholder="Provincia*"
                                    setCounty={setCounty}
                                    value={county}
                                    disabled={!nation}
                                    nationId={nationId}
                                    foreignNation={foreignNation}
                                />
                                <div className="form-control-invalid">
                                    {errors.countyId && errors.countyId.message}
                                </div>
                            </Col>
                            <Col md={4} className="form-group">
                                <AddressCity
                                    placeholder="Comune*"
                                    setCity={setCity}
                                    countyId={countyId}
                                    value={city}
                                    disabled={!county}
                                    nationId={nationId}
                                />
                                <div className="form-control-invalid">
                                    {errors.cityId && errors.cityId.message}
                                </div>
                            </Col>
                        </Row>
                        <Row className="signup-row">
                            <Col md={3} className="form-group">
                                {isForeign ?
                                    <ValidateInput
                                        name="zip"
                                        label="Cap*"
                                        type="text"
                                        watch={watch("zip")}
                                        pattern="[0-9]"
                                        maxLength={5}
                                        value={zip}
                                        disabled={!city}
                                        register={register({
                                            required: "Campo obbligatorio",
                                        })}
                                        onChange={(e) => setZipCode(e.target.value)}
                                    />
                                    :
                                    <AddressZip
                                        placeholder="Cap*"
                                        cityId={cityId}
                                        setZip={setZipCode}
                                        value={zip}
                                        disabled={!city}
                                    />
                                }
                                <div className="form-control-invalid">
                                    {errors.zip && errors.zip.message}
                                </div>
                            </Col>
                            <Col md={6} className="form-group">
                                {/* <ValidateInput
                                    name="address"
                                    label="Indirizzo*"
                                    type="text"
                                    value={props?.user?.address && props.user.address.address}
                                    watch={watch("address")}
                                    register={register({
                                        required: "Campo obbligatorio"
                                    })}
                                /> */}
                                <AddressDropdown
                                    placeholder="Indirizzo*"
                                    setAddress={setAddress}
                                    nation={nation}
                                    cityName={city}
                                    value={deliveryAddress}
                                    disabled={!city}
                                />
                                <div className="form-control-invalid">
                                    {errors.address && errors.address.message}
                                </div>
                            </Col>
                            <Col md={3} className="form-group">
                                <ValidateInput
                                    name="streetNumber"
                                    label="Civico*"
                                    type="text"
                                    value={props?.user?.address && props.user.address.streetNumber}
                                    watch={watch("streetNumber")}
                                    register={register({
                                        required: "Campo obbligatorio"
                                    })}
                                />
                                <div className="form-control-invalid">
                                    {errors.streetNumber && errors.streetNumber.message}
                                </div>
                            </Col>
                        </Row>

                        <div className="d-none">
                            <ValidateInput
                                name="id"
                                type="text"
                                value={props.user && props.user.address && props.user.address.id}
                                watch={watch("id")}
                                register={register}
                            />
                        </div>

                        {/* <span style={{ marginTop: "40px" }} className="profile-subtitle">{t('signup.privacy')}</span>
                        <textarea
                            rows="10"
                            cols="69"
                            // defaultValue={privacy}
                            defaultValue="Privacy policy"
                            readOnly
                        /> */}

                        <p className="profile-subtitle">{t('Consensi A')}</p>

                        <div className="custom-control custom-checkbox">
                            <input className="custom-control-input" type="checkbox" id="accept1" name="accept1"
                                defaultChecked={props.user && props.user.accept1}
                                disabled={props.user && props.user.accept1}
                                ref={register({
                                    required: "Campo obbligatorio"
                                })}
                            />
                            {/* <label className="custom-control-label" htmlFor="accept1">
                                {t("profile.acceptTermsAndConditions")}
                            </label> */}
                            <label className="custom-control-label" htmlFor="accept1">
                                Dichiaro di aver letto e di accettare integralmente
                                i <a href="https://laspesaacasa.siconte.it/resources/01/CONDIZIONI_DI_VENDITA_ver_23_02_2021.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    Termini e le condizioni
                                </a> di servizio*
                            </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                            <input className="custom-control-input" type="checkbox" id="accept2" name="accept2"
                                defaultChecked={props.user && props.user.accept2}
                                disabled={props.user && props.user.accept2}
                                ref={register({
                                    required: "Campo obbligatorio"
                                })}
                            />
                            {/* <label className="custom-control-label" htmlFor="accept2">
                                {t("profile.acceptPrivacyPolicy")}
                            </label> */}
                            <label className="custom-control-label" htmlFor="accept2">
                                {/* {t("signup.acceptPrivacyPolicy")}* */}
                                Dichiaro di aver letto
                                la <a href="https://laspesaacasa.siconte.it/resources/01/MCG_CEDI_Info_Spesa_a_casa_v_1_Rev_2021_02_03.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    Privacy Policy
                                </a> indicata nella informativa per la registrazione al servizio laspesaacasa.siconte.it
                            </label>
                        </div>

                        <p className="profile-subtitle">{t('Consensi B')}</p>

                        <div className="custom-control custom-checkbox">
                            <input className="custom-control-input" type="checkbox" id="accept3" name="accept3"
                                defaultChecked={props.user && props.user.accept3}
                                ref={register}
                            />
                            <label className="custom-control-label" htmlFor="accept3">
                                {t("signup.acceptMarketing")}
                            </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                            <input className="custom-control-input" type="checkbox" id="accept4" name="accept4"
                                defaultChecked={props.user && props.user.accept4}
                                ref={register}
                            />
                            <label className="custom-control-label" htmlFor="accept4">
                                {t("signup.acceptProfiling")}
                            </label>
                        </div>


                        <div className="profile-save">
                            <button className="cta" type="button" onClick={() => setShowModal(true)} disabled={props.isProfileUpdating} style={{ marginRight: '5px' }}>
                                <span>{t("delete")}</span>
                            </button>
                            <button className="cta" type="submit" disabled={props.isProfileUpdating}>
                                {props.isProfileUpdating ?
                                    <Loader
                                        type="ThreeDots"
                                        color="#ffffff"
                                        height={50}
                                        width={50}
                                    />
                                    :
                                    <span>{t("save")}</span>
                                }
                            </button>
                        </div>
                    </div>


                </form>
            }
        </Translation>
    );
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        isProfileUpdating: state.user.isProfileUpdating
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateUser: (data) => dispatch(actionCreators.updateUser(data)),
        deleteUser: (data) => dispatch(actionCreators.deleteUser(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);