import React from "react"

import "./header-item.styles.scss";

const HeaderItem = (props) => {

    return (
        <div className="header-item-content">
            <div id={props.id} className={`header-item ${props.actived ? "header-item-actived" : ""}`}
                onClick={props.onClick}
            >
                {props.children}
            </div>
            <div className="header-item-label">{props.label}</div>
        </div>


    );
}

export default HeaderItem;