import i18n from "../../i18n"
import React from "react";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import CurrentCart from "./CurrentCart/CurrentCart"
import CartMenu from "./CartMenu/CartMenu"
import { Translation } from 'react-i18next';
import { connect } from "react-redux"
import * as actionCreators from "../../redux/store/actions/index"
import { Redirect } from 'react-router'
import FreezeCheckPopup from "./CartMenu/FreezeCheck/FreezeCheckPopup";
import { ecommerceCartsService } from "../../services/ecommerce/EcommerceCartsService";

class Cart extends React.Component {
  componentDidMount() {
    this.props.current();
    if (this.props.cart) {
      ecommerceCartsService.freezeCheck(this.props.cart.id, false)
        .then(response => {
          if (response && response.responseCode === 4105) {
            this.props.freezeCheck(response.data, true);
          }
        })
    }
  }

  render() {
    const label = <span>{i18n.t("cart.current")}</span>;
    const { cart, updatedItems, removedItems, showFreezePopup, closeFreezePopup } = this.props;
    if (cart) {
      return (
        <Translation>
          {t =>
            <>
              <div className="container-fluid mobile-view-search">
                <Row>
                  <Col sm={12} xl={7}>
                    <CurrentCart cart={cart} />
                  </Col>
                  <Col style={{ backgroundColor: "#fbfbfb" }} sm={12} xl={5}>
                    <CartMenu
                      changeView={this.changeView}
                      label={label}
                    />
                  </Col>
                </Row>
              </div>
              <FreezeCheckPopup
                open={showFreezePopup}
                closePopup={closeFreezePopup}
                updatedItems={updatedItems}
                removedItems={removedItems}
              />
            </>
          }
        </Translation>
      );
    } else return <Redirect to='/cart/create' />
  }
}

const mapStateToProps = state => {
  return {
    cart: state.cart.cart,
    updatedItems: state.cart.updatedItems,
    removedItems: state.cart.removedItems,
    showFreezePopup: state.cart.showFreezePopup
  };
}

const mapDispatchToProps = dispatch => {
  return {
    current: () => dispatch(actionCreators.current()),
    freezeCheck: (data, showPopup) => dispatch(actionCreators.freezeCheck(data, showPopup)),
    closeFreezePopup: () => dispatch(actionCreators.closeFreezePopup()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Cart);

