import React from "react"
import { useForm } from 'react-hook-form'
import { Translation } from 'react-i18next';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ValidateInput from "../../../components/UI/Input/ValidateInput";

import "../signup.styles.scss";

function SignupStep3(props) {
    const { register, handleSubmit, errors, watch } = useForm({
        mode: 'onBlur',
    });

    const onSubmit = data => {
        props.nextStep(data);
    }

    const { data } = props;
    
    return (
        <Translation>
            {t =>
                <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off"
                    className={`signup needs-validation ${errors ? "had-validation" : ""}`}>
                    <div className="signup-content">
                        <div className="signup-card">
                            <Row>
                                <Col md={6} className="form-group">
                                    <ValidateInput
                                        name="username"
                                        label="Indirizzo email*"
                                        type="email"
                                        watch={watch("username")}
                                        value={data && data.username}
                                        register={register({
                                            required: "Campo obbligatorio",
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: "Indirizzo email non valido"
                                            }
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.username && errors.username.message}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} className="form-group">
                                    <ValidateInput
                                        name="password"
                                        label="password"
                                        type="password"
                                        watch={watch("password")}
                                        register={register({
                                            required: "Campo obbligatorio",
                                            minLength: { value: 8, message: "La password deve essere lunga almeno 8 caratteri" }
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.password && errors.password.message}
                                    </div>
                                </Col>
                                <Col md={6} className="form-group">
                                    <ValidateInput
                                        name="passwordConfirm"
                                        label="passwordConfirm"
                                        type="password"
                                        watch={watch("passwordConfirm")}
                                        register={register({
                                            required: "Campo obbligatorio",
                                            validate: {
                                                passwordMatch: value => value === watch('password')
                                            }
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.passwordConfirm && errors.passwordConfirm.message}
                                        {errors.passwordConfirm && errors.passwordConfirm.type === "passwordMatch" && (
                                            <span>{t("signup.passwordsDoesNotMatch")}</span>
                                        )}
                                    </div>
                                </Col>
                                <Row className="container-fluid">
                                    <Col md={12} className="form-group">
                                        <small id="passwordHelp" className="form-text text-muted">
                                            La password deve essere lunga almeno 8 caratteri e contenere
                                            almeno tre tra: lettere minuscole, maiuscole, numeri e simboli
                                    </small>
                                    </Col>
                                </Row>
                            </Row>
                        </div>


                        <div className="signup-actions text-center">
                            <button className="cta" type="submit">
                                <span>{t("signup.nextStep")}</span>
                            </button>
                        </div>
                    </div>
                </form>
            }
        </Translation >
    );
}

export default SignupStep3;