/* eslint-disable */

import * as actionTypes from "../actions/actionTypes";
import { createReducer } from '@reduxjs/toolkit'

const initialState = {
    user: JSON.parse(localStorage.getItem("user")),
    latitude: null,
    longitude: null,
    showOverlay: false,
    showLogin: false,
    isProfileUpdating: false
}

const userReducer = createReducer(initialState, {
    [actionTypes.LOGIN]: (state, action) => { state.user = action.user, state.showLogin = action.showLogin },
    [actionTypes.LOGOUT]: (state, action) => { state.user = action.user },
    [actionTypes.USER_UPDATE]: (state, action) => { 
        state.user = action.user,
        state.isProfileUpdating = action.isProfileUpdating 
    },
    [actionTypes.IS_LOGGED]: (state, action) => { state.user = action.user },
    [actionTypes.COORDINATE]: (state, action) => {
        state.latitude = action.latitude,
            state.longitude = action.longitude
    },
    [actionTypes.OVERLAY]: (state, action) => { state.showOverlay = action.showOverlay },
    [actionTypes.SHOW_LOGIN]: (state, action) => { state.showLogin = action.showLogin },
    [actionTypes.PROFILE_UPDATING]: (state, action) => { 
        state.isProfileUpdating = action.isProfileUpdating 
    },
})

export default userReducer;

