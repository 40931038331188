import React, { useState, useEffect } from "react"
import { useForm } from 'react-hook-form'
import { Translation } from 'react-i18next';
import { format, parseISO } from "date-fns";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import AddressCity from "../../../components/UI/AddressSelect/AddressCity";
import AddressCounty from "../../../components/UI/AddressSelect/AddressCounty";
import ValidateInput from "../../../components/UI/Input/ValidateInput";
import AddressZip from "../../../components/UI/AddressSelect/AddressZip";
import AddressDropdown from "../../../components/UI/AddressSelect/AddressDropdown";
import AddressNation from "../../../components/UI/AddressSelect/AddressNation";

import "../signup.styles.scss";

function SignupStep2(props) {
    const { register, handleSubmit, errors, watch, setValue } = useForm({
        mode: 'onBlur',
    });

    const { data } = props;
    const address = props.user?.address;
    const [nationId, setNationId] = useState(data ? data.nationId : null);
    const [nationName, setNationName] = useState(data ? data.nation : "");
    const [countyId, setCountyId] = useState(data ? data.countyId : null);
    const [countyName, setCountyName] = useState(data ? data.county : "");
    const [cityId, setCityId] = useState(data ? data.cityId : null);
    const [cityName, setCityName] = useState(data ? data.city : "");
    const [isForeign, setIsForeign] = useState();
    const [foreignNation, setForeignNation] = useState();
    const [birthForeignNation, setBirthForeignNation] = useState();
    const [zip, setZip] = useState(data ? data.zip : null);
    const [deliveryAddress, setDeliveryAddress] = useState((data && typeof data?.address !== "object") ? data.address : "");

    const [birthNationId, setBirthNationId] = useState(data ? data.birthNationId : null);
    const [birthNationName, setBirthNationName] = useState(data ? data.birthNation : null);
    const [birthCountyId, setBirthCountyId] = useState(data ? data.birthCountyId : null);
    const [birthCountyName, setBirthCountyName] = useState(data ? data.birthCounty : null);
    const [birthCityId, setBirthCityId] = useState(data ? data.birthCityId : null);
    const [birthCityName, setBirthCityName] = useState(data ? data.birthCity : null);

    // console.log(watch());

    useEffect(() => {
        register(
            { name: "nationId" },
            { required: "Campo obbligatorio" }
        )
        register(
            { name: "countyId" },
            { required: "Campo obbligatorio" }
        )
        register(
            { name: "cityId" },
            { required: "Campo obbligatorio" }
        )
        register(
            { name: "zip" },
            { required: "Campo obbligatorio" }
        )
        register(
            { name: "address" },
            { required: "Campo obbligatorio" }
        )

        register(
            { name: "birthNationId" },
            { required: "Campo obbligatorio" }
        )
        register(
            { name: "birthCountyId" },
            { required: "Campo obbligatorio" }
        )
        register(
            { name: "birthCityId" },
            { required: "Campo obbligatorio" }
        )

        register({ name: "city" })
        register({ name: "county" })
        register({ name: "nation" })
        register({ name: "birthCity" })
        register({ name: "birthCounty" })
        register({ name: "birthNation" })

        setValue("nationId", nationId);
        setValue("countyId", countyId);
        setValue("cityId", cityId);
        setValue("zip", zip);
        setValue("address", deliveryAddress);
        setValue("birthNationId", birthNationId);
        setValue("birthCountyId", birthCountyId);
        setValue("birthCityId", birthCityId);

        setValue("nation", nationName);
        setValue("city", cityName);
        setValue("county", countyName);
        setValue("birthNation", nationName);
        setValue("birthCity", cityName);
        setValue("birthCounty", countyName);

    }, [register, nationId, countyId, cityId, zip, deliveryAddress,
        cityName, countyName, nationName, birthCityId,
        birthCountyId, birthNationId, props.type, setValue])

    const setNation = item => {
        setValue("nationId", item.id);
        setValue("nation", item.name);
        setNationId(item.id)
        setNationName(item.name)
        setForeignNation(item.foreignNation);

        setCountyId(null);
        setCountyName("");
        setCityId(null)
        setCityName("")
        setAddress("")
        setZip("")
    }

    const setCounty = item => {
        setValue("countyId", item.id);
        setValue("county", item.name);
        setCountyId(item.id);
        setCountyName(item.name);

        // reset also city address and zip
        setCityId(null)
        setCityName("")
        setAddress("")
        setZip("")
    }

    const setCity = item => {
        if (item.foreignCity) {
            setValue("cityId", item.name);
            setCityId(item.name);
            setIsForeign(true)
        } else {
            setValue("cityId", item.id);
            setCityId(item.id);
            setIsForeign(false)
        }
        setValue("city", item.name);
        setCityName(item.name)
        setAddress("")
        setZip("")
    }

    const setAddress = item => {
        setValue("address", item);
        setDeliveryAddress(item);
    }

    const setZipCode = item => {
        setValue("zip", item);
        setZip(item);
    }

    const setBirthNation = item => {
        setValue("birthNationId", item.id);
        setValue("birthNation", item.name);
        setBirthNationId(item.id)
        setBirthNationName(item.name)
        setBirthForeignNation(item.foreignNation);

        setBirthCountyId(null);
        setBirthCountyName("");
        setBirthCityId(null)
        setBirthCityName("")
    }

    const setBirthCounty = item => {
        setValue("birthCountyId", item.id);
        setValue("birthCounty", item.name);
        setBirthCountyId(item.id);
        setBirthCountyName(item.name);

        // reset also city address and zip
        setBirthCityId(null)
        setBirthCityName("")
    }

    const setBirthCity = item => {
        if (item.foreignCity) {
            setValue("birthCityId", item.name);
            setBirthCityId(item.name);
        } else {
            setValue("birthCityId", item.id);
            setBirthCityId(item.id);
        }
        setValue("birthCity", item.name);
        setBirthCityName(item.name)
    }

    const onSubmit = data => {
        props.nextStep(data);
    }

    return (
        <Translation>
            {t =>
                <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="new-password"
                    className={`signup needs-validation ${errors ? "had-validation" : ""}`}>
                    <div className="signup-content">
                        <div className="signup-card">
                            <div className="card-chapter" style={{ marginBottom: "25px" }}>{t("Dati anagrafici")}</div>
                            <Row>
                                <Col md={6} className="form-group">
                                    <ValidateInput
                                        name="firstName"
                                        label="Nome*"
                                        type="text"
                                        watch={watch("firstName")}
                                        value={props.user && props.user.firstName}
                                        disabled={true}
                                        register={register({
                                            required: "Campo obbligatorio",
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.firstName && errors.firstName.message}
                                    </div>
                                </Col>
                                <Col md={6} className="form-group">
                                    <ValidateInput
                                        name="lastName"
                                        label="Cognome*"
                                        type="text"
                                        watch={watch("lastName")}
                                        value={props.user && props.user.lastName}
                                        disabled={true}
                                        register={register({
                                            required: "Campo obbligatorio",
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.lastName && errors.lastName.message}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} className="form-group">
                                    <ValidateInput
                                        name="birthDate"
                                        label="Data di nascita*"
                                        type="text"
                                        watch={watch("birthDate")}
                                        value={props.user && props.user.birthDate &&
                                            format(parseISO(props.user.birthDate), "yyyy-MM-dd")}
                                        disabled={true}
                                        style={{ backgroundColor: "#eee" }}
                                        register={register({
                                            required: "Campo obbligatorio",
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.birthDate && errors.birthDate.message}
                                    </div>
                                </Col>
                                {props.type === "fetch" &&
                                    <Col md={6} className="form-group">
                                        <ValidateInput
                                            name="code"
                                            label="N° carta fedeltà*"
                                            type="text"
                                            watch={watch("code")}
                                            value={props.user && props.user.cards && props.user.cards[0] &&
                                                props.user.cards[0].code}
                                            disabled={true}
                                            register={register({
                                                required: "Campo obbligatorio",
                                            })}
                                        />
                                        <div className="form-control-invalid">
                                            {errors.code && errors.code.message}
                                        </div>
                                    </Col>
                                }
                            </Row>
                            <Row>
                                <Col md={6} className="form-group">
                                    <div className="d-flex">
                                        <div className="gender">Sesso*</div>
                                        <div className="radio-button">
                                            <div className="custom-control custom-radio">
                                                <input type="radio" className="custom-control-input"
                                                    id="male"
                                                    name="gender"
                                                    value="MALE"
                                                    defaultChecked={data && data?.gender === "MALE"}
                                                    disabled={data && data.gender}
                                                    ref={register({ required: "Campo obbligatorio", })}
                                                />
                                                <label className="custom-control-label" htmlFor="male">
                                                    Uomo
                                                </label>
                                            </div>
                                        </div>
                                        <div className="radio-button">
                                            <div className="custom-control custom-radio">
                                                <input type="radio" className="custom-control-input"
                                                    id="female"
                                                    name="gender"
                                                    value="FEMALE"
                                                    defaultChecked={data && data?.gender === "FEMALE"}
                                                    disabled={data && data.gender}
                                                    ref={register({ required: "Campo obbligatorio", })}
                                                />
                                                <label className="custom-control-label" htmlFor="female">
                                                    Donna
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-control-invalid">
                                        {errors.gender && errors.gender.message}
                                    </div>
                                </Col>
                                <Col md={6} className="form-group">
                                    <AddressNation
                                        placeholder="Nazione di nascita*"
                                        setNation={setBirthNation}
                                        value={birthNationName}
                                        disabled={props.user && props.user.birthNationId}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.birthNationId && errors.birthNationId.message}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} className="form-group">
                                    <AddressCounty
                                        placeholder="Provincia di nascita*"
                                        setCounty={setBirthCounty}
                                        value={birthCountyName}
                                        disabled={!birthNationId || (props.user && props.user.birthCountyId)}
                                        nationId={birthNationId}
                                        foreignNation={birthForeignNation}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.birthCountyId && errors.birthCountyId.message}
                                    </div>
                                </Col>
                                <Col md={6} className="form-group">
                                    <AddressCity
                                        placeholder="Comune di nascita*"
                                        setCity={setBirthCity}
                                        countyId={birthCountyId}
                                        nationId={birthNationId}
                                        value={birthCityName}
                                        disabled={!birthCountyId || (props.user && props.user.birthCityId)}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.birthCityId && errors.birthCityId.message}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} className="form-group">
                                    <ValidateInput
                                        name="mobilePhone"
                                        label="Telefono cellulare*"
                                        type="tel"
                                        value={data && data.mobilePhone}
                                        watch={watch("mobilePhone")}
                                        register={register({
                                            required: "Campo obbligatorio",
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.mobilePhone && errors.mobilePhone.message}
                                    </div>
                                </Col>
                                <Col md={6} className="form-group">
                                    <ValidateInput
                                        name="homePhone"
                                        label="Telefono fisso"
                                        type="tel"
                                        watch={watch("homePhone")}
                                        register={register}
                                        value={data && data.homePhone}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.homePhone && errors.homePhone.message}
                                    </div>
                                </Col>
                            </Row>
                            <div className="card-chapter" style={{ marginBottom: "25px" }}>{t("Indirizzo di residenza")}</div>
                            <Row>
                                <Col md={6} className="form-group">
                                    <AddressNation
                                        placeholder="Nazione*"
                                        setNation={setNation}
                                        value={nationName}
                                        disabled={address && address.nationId}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.nationId && errors.nationId.message}
                                    </div>
                                </Col>
                                <Col md={6} className="form-group">
                                    <AddressCounty
                                        placeholder="Provincia*"
                                        setCounty={setCounty}
                                        value={countyName}
                                        disabled={!nationId || (address && address.countyId)}
                                        nationId={nationId}
                                        foreignNation={foreignNation}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.countyId && errors.countyId.message}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} className="form-group">
                                    <AddressCity
                                        placeholder="Comune*"
                                        setCity={setCity}
                                        countyId={countyId}
                                        nationId={nationId}
                                        value={cityName}
                                        disabled={!countyId || (address && address.cityId)}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.cityId && errors.cityId.message}
                                    </div>
                                </Col>
                                <Col md={6} className="form-group">
                                    {isForeign ?
                                        <ValidateInput
                                            name="zip"
                                            label="Cap*"
                                            type="text"
                                            watch={watch("zip")}
                                            pattern="[0-9]"
                                            maxLength={5}
                                            value={zip}
                                            disabled={!cityId || (address && address.zip)}
                                            register={register({
                                                required: "Campo obbligatorio",
                                            })}
                                            onChange={(e) => setZipCode(e.target.value)}
                                        />
                                        :
                                        <AddressZip
                                            placeholder="Cap*"
                                            cityId={cityId}
                                            setZip={setZipCode}
                                            value={zip}
                                            disabled={!cityId || (address && address.zip)}
                                        />
                                    }
                                    <div className="form-control-invalid">
                                        {errors.zip && errors.zip.message}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={9} className="form-group">
                                    <AddressDropdown
                                        placeholder="Indirizzo*"
                                        setAddress={setAddress}
                                        nation={nationName}
                                        cityName={cityName}
                                        value={deliveryAddress}
                                        disabled={!cityName || (address && address.address)}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.address && errors.address.message}
                                    </div>
                                </Col>
                                <Col md={3} className="form-group">
                                    <ValidateInput
                                        name="streetNumber"
                                        label="Civico*"
                                        type="text"
                                        watch={watch("streetNumber")}
                                        value={(data && data.streetNumber) ? data.streetNumber : ""}
                                        disabled={!deliveryAddress || (address && address.streetNumber)}
                                        register={register({
                                            required: "Campo obbligatorio",
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.streetNumber && errors.streetNumber.message}
                                    </div>
                                </Col>
                            </Row>
                        </div>


                        <div className="signup-actions text-center">
                            <button className="cta" type="submit">
                                <span>{t("signup.nextStep")}</span>
                            </button>
                        </div>
                    </div>
                </form>
            }
        </Translation >
    );
}

export default SignupStep2;