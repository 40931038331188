import React from "react";
import { Translation } from 'react-i18next';
import OrderItem from "./OrderItem/OrderItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18n from "../../../i18n";
import Popup from "../../../components/UI/Popup/Popup";
import renderPrice from "../../../utils/renderPrice";

export default class Order extends React.Component {
    renderCartTotal = (item) => {
        let result;
        if (item && item.cart) {
            if (item.cart.netTotal && item.cart.total &&
                item.cart.netTotal !== item.cart.total) {
                result =
                    <>
                        <nobr><span className="text-strike" aria-label="Testo cancellato">
                            {`${renderPrice(item.cart.total.toFixed(2))} \u20AC`}
                        </span></nobr>
                        <nobr><span className="ml-1">
                            {`${renderPrice(item.cart.netTotal.toFixed(2))} \u20AC`}
                        </span></nobr>
                    </>
            } else {
                result = `${renderPrice(item.cart.netTotal.toFixed(2))} \u20AC`
            }
            return result;
        }
    }

    renderOperatorCartTotal = (item) => {
        let result;
        if (item && item.operatorCart) {
            if (item.operatorCart.netTotal && item.operatorCart.total &&
                item.operatorCart.netTotal !== item.operatorCart.total) {
                result =
                    <>
                        <nobr><span className="text-strike" aria-label="Testo cancellato">
                            {`${renderPrice(item.operatorCart.total.toFixed(2))} \u20AC`}
                        </span></nobr>
                        <nobr><span className="ml-1">
                            {`${renderPrice(item.operatorCart.netTotal.toFixed(2))} \u20AC`}
                        </span></nobr>
                    </>
            } else {
                result = `${renderPrice(item.operatorCart.netTotal.toFixed(2))} \u20AC`
            }
            return result;
        }
    }

    renderTotalToPay = (item) => {
        let result;
        if (item) {
            if (item.operatorCart && item.operatorCart.totalToPay) {
                result = `${renderPrice(item.operatorCart.totalToPay.toFixed(2))} \u20AC`
            } else {
                result = `${renderPrice(item.cart.totalToPay.toFixed(2))} \u20AC`
            }
            return result;
        }
    }

    renderAbortOrder = (order, abort, t) => {
        let result;
        order.status === "TO_BE_PREPARED" ?
            result = (
                <Translation>
                    {t =>
                        <Popup
                            action={() => abort(order.id)}
                            title={i18n.t("order.abort")}
                            content={i18n.t("order.abort.question")}
                            button={i18n.t("delete")}
                            trigger={
                                <span className="order-delete-button">
                                    <div className="d-none d-lg-inline">
                                        {i18n.t("order.abortOrder")}
                                    </div>
                                    <button className="d-lg-none ml-3 button-clean outline">
                                        <FontAwesomeIcon icon="trash-alt" style={{ width: "15px", height: "20px" }} />
                                    </button>
                                </span>
                            }
                        />
                    }
                </Translation>
            ) : result = null;

        return result;
    }

    renderCopyOrder = (order, copy, t) => {
        return (
            <Popup
                action={() => copy(order.id)}
                title={i18n.t("order.copy")}
                content={i18n.t("order.copy.question")}
                button={i18n.t("copy")}
                trigger={
                    <span className="order-copy-button">
                        <div className="d-none d-lg-inline">
                            {i18n.t("order.copyOrder")}
                        </div>
                        <button className="d-lg-none ml-3 button-clean outline">
                            <FontAwesomeIcon icon="copy" style={{ width: "15px", height: "20px" }} />
                        </button>
                    </span>
                }
            />
        );
    }

    render() {
        const { order, items, abort, copy } = this.props;
        return (
            <Translation>
                {t =>
                    <>
                        <div className="mt-3 order-header">
                            <span className="signup-form-title pt-sm-0 pt-lg-2">
                                {order && order.cart.title}
                            </span>
                            <span>
                                {order && this.renderAbortOrder(order, abort)}
                                {order && this.renderCopyOrder(order, copy, t)}
                            </span>
                        </div>
                        <div className="mb-5 ml-3">
                            <div className="cart-heading d-none d-sm-block mb-2">
                                <div className="row">
                                    <div style={{ width: "70px" }}>{t("products")}</div>
                                    <div className="col pl-0 pr-3">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-4 text-left px-0" />
                                                <div className="col-2 text-right px-0">
                                                    {t("order.price")}
                                                </div>
                                                <div className="col-1"></div>
                                                <div className="col-2 text-center">{t("amount")}</div>
                                                <div className="col-2 text-right px-0">
                                                    {t("total")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {items.map((item) => (
                                <OrderItem key={item.id} item={item} />
                            ))}
                            <div className="pt-sm-2 pt-0"></div>


                            <div className="cart-product-details">
                                {order && !order.enabledProductsReplacementChoice &&
                                    <div className="row checkout-summary-total pt-3 pb-3">
                                        <div style={{ width: "65px" }}>
                                            <nobr>
                                                <span
                                                    className="checkout-summary-total-text-info">Consenso prodotto sostitutivo</span>
                                            </nobr>
                                        </div>
                                        <div className="col pl-0 pr-3">
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-2 col-sm-4 text-left px-0" />
                                                    <div className="col-3 col-sm-3 text-center px-0" />
                                                    {/* <div className="col-3 col-sm-2 text-center px-0" /> */}
                                                    <div className="col-7 col-sm-3 text-right px-0">
                                                        <div>
                                                            <label>
                                                                NO
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {order && order.cart && order.cart.integrationNotes &&
                                    <div className="row checkout-summary-total pt-3 pb-3">
                                        <div style={{ width: "65px" }}>
                                            <nobr>
                                                <span
                                                    className="checkout-summary-total-text-info">{t("userData.integrationNote")}</span>
                                            </nobr>
                                        </div>
                                        <div className="col pl-0 pr-3">
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-2 col-sm-4 text-left px-0" />
                                                    <div className="col-3 col-sm-3 text-center px-0" />
                                                    {/* <div className="col-3 col-sm-2 text-center px-0" /> */}
                                                    <div className="col-7 col-sm-5 text-right px-0">
                                                        <span style={{ fontFamily: 'Montserrat-Bold' }}>
                                                            {order.cart.integrationNotes}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="row checkout-summary-total pt-3">
                                    <div style={{ width: "65px" }}>
                                        <nobr>
                                            <span
                                                className="checkout-summary-total-text-info">{t("order.totalProductsOrdered")}</span>
                                        </nobr>
                                    </div>
                                    <div className="col pl-0 pr-3">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-2 col-sm-4 text-left px-0" />
                                                <div className="col-3 col-sm-3 text-center px-0" />
                                                <div className="col-3 col-sm-2 text-center px-0">
                                                    <span className="d-none d-sm-block cart-product-total">
                                                        {order && order.cart && order.cart.itemsCount}
                                                    </span>
                                                </div>
                                                <div className="col-sm-2 col-4 text-right px-0">
                                                    <span className="checkout-summary-total-price">
                                                        {this.renderCartTotal(order)}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {order && order.operatorCart && order.status &&
                                    order.status !== "TO_BE_PREPARED" && order.status !== "IN_PREPARATION" &&
                                    <div className="row pt-2">
                                        <div style={{ width: "65px" }}>
                                            <nobr>
                                                <span
                                                    className="checkout-summary-total-text-info">{t("order.totalProductsToDispatch")}</span>
                                            </nobr>
                                        </div>
                                        <div className="col pl-0 pr-3">
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-2 col-sm-4 text-left px-0" />
                                                    <div className="col-3 col-sm-3 text-center px-0" />
                                                    <div className="col-3 col-sm-2 text-center px-0">
                                                        <span className="d-none d-sm-block cart-product-total">
                                                            {order && order.operatorCart && order.operatorCart.itemsCount}
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-2 col-4 text-right px-0">
                                                        <span className="checkout-summary-total-price">
                                                            {this.renderOperatorCartTotal(order)}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }


                                {order && order.cart && order.cart.fees && order.cart.fees.map(fee => (
                                    <div key={fee.description} className="row pt-2">
                                        <div style={{ width: "65px" }}>
                                            <nobr>
                                                <span className="checkout-summary-total-text-info">
                                                    {fee.description}
                                                </span>
                                            </nobr>
                                        </div>
                                        <div className="col pl-0 pr-3">
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-8 col-sm-9 text-center" />
                                                    <div className="col-sm-2 col-4 text-right px-0">
                                                        <span
                                                            className="checkout-summary-total-price">{`${fee.value.toFixed(2)} \u20AC`}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                <div className="row pt-2">
                                    <div style={{ width: "65px" }}>
                                        <nobr>
                                            <span className="checkout-summary-total-text-info">
                                                {t("order.purchaseTotal")}
                                            </span>
                                        </nobr>
                                    </div>
                                    <div className="col pl-0 pr-3">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-8 col-sm-9 text-center" />
                                                <div className="col-sm-2 col-4 text-right px-0">
                                                    <span
                                                        className="checkout-summary-total-price">{this.renderTotalToPay(order)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {order && order.operatorCart && order.operatorCart.payed &&
                                    <>
                                        <div className="row pt-2">
                                            <div style={{ width: "65px" }}>
                                                <nobr>
                                                    <span className="checkout-summary-total-text-info">
                                                        {t("order.billTotal")}
                                                    </span>
                                                </nobr>
                                            </div>
                                            <div className="col pl-0 pr-3">
                                                <div className="container-fluid">
                                                    <div className="row">
                                                        <div className="col-8 col-sm-9 text-center" />
                                                        <div className="col-sm-2 col-4 text-right px-0">
                                                            <span className="checkout-summary-total-price">
                                                                {`${order.operatorCart.payed.toFixed(2)} \u20AC`}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ fontSize: "12px" }} className="row pt-1 ">
                                            {t("order.billAdvice")}
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </>
                }
            </Translation>
        );
    }
}



