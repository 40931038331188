import React from "react"
import categories from "./Service"
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";

import CategoryIcon from "./CategoryIcon";
import { Row } from "react-bootstrap";

export default class Categories extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            isLoaded: false
        }
    }

    componentDidMount() {
        categories().then(response => {
            this.setState({
                categories: response.data,
                isLoaded: true
            })
        }).catch((error) => {
            console.log(error);
        })
    }

    render() {
        const { categories, isLoaded } = this.state;
        if (isLoaded) {
            return (
                <Translation>
                    {t =>
                        <div className="container-fluid modal-mobile-scrolling"
                            style={{ marginBottom: "45px" }}>
                            <div className="row">
                                <div className="category-navigation-view container-fluid">
                                    <div className="row">
                                        {/*{config.ENABLED_SLIST &&*/}
                                        {/*    <div className="col-10 col-sm-6 col-lg-4 column">*/}
                                        {/*        <Link to="/favorites"*/}
                                        {/*            style={{ textDecoration: 'none' }}*/}
                                        {/*            className="category-list-item favorites"*/}
                                        {/*            onClick={this.props.closeAll}*/}
                                        {/*        >*/}
                                        {/*            {t("favorites")}*/}
                                        {/*        </Link>*/}
                                        {/*    </div>*/}
                                        {/*}*/}

                                        {categories && categories.map((category) => (
                                            <div className="col-10 col-sm-6 col-lg-4 column" key={category.id}>
                                                <Link
                                                    to={{ pathname: "/category", state: { category: category } }}
                                                    style={{ textDecoration: 'none' }}
                                                    className="category-list-item"
                                                    onClick={this.props.closeAll}
                                                >
                                                    <Row>
                                                        <div className="d-flex flex-row">
                                                            <div>
                                                                <CategoryIcon category={category.description} />
                                                                <span>{category.description}</span>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                </Link>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </Translation>
            );
        } else {
            return null;
        }
    }
}