import React from "react";
import Carousel from 'react-bootstrap/Carousel'
import config from "../../../config/config";
import banners from "./Service"
export default class HomeCarousel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            banners: []
        };
    }

    componentDidMount() {
        const storeId = this.props.cart ? this.props.cart.storeId : config.DEFAULT_STORE_ID;
        banners(storeId).then(response => {
            this.setState({
                isLoaded: true,
                banners: response.data
            })
        }).catch(error => {
            console.log(error);
        });
    }

    render() {
        const { isLoaded, banners } = this.state;
        return (isLoaded ?
            <Carousel className="Carousel">
                {banners.map((item, index) => (
                    <Carousel.Item key={item.id}>
                        <div role="img" style={{ backgroundImage: `url(${item.imageUrl})` }} alt="image"></div>
                    </Carousel.Item>
                ))}
            </Carousel>

            : null
        );
    }

}