import { handleResponse } from '../../utils/handleResponse';
import { checkResponse } from '../../utils/checkResponse';
import config from '../../config/config';

export const addressesService = {
    states, counties, cities, zipcodes, fetchStreets, nations
};

function states() {
    return (
        fetch(`${config.baseUrl}/cli/addresses/states`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
            mode: "cors",
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function counties(nationId, query) {
    return (
        fetch(`${config.baseUrl}/cli/addresses/counties?nationId=${nationId}&q=${query}`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
            mode: "cors",
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function cities(countyId, query, nationId) {
    return (
        fetch(`${config.baseUrl}/cli/addresses/cities?countyId=${countyId}&q=${query}&nationId=${nationId}`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
            mode: "cors",
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function zipcodes(cityId, query) {
    return (
        fetch(`${config.baseUrl}/cli/addresses/zipcodes?cityId=${cityId}&q=${query}`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
            mode: "cors",
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function fetchStreets(cityName, nation, query) {
    return (
        fetch(`${config.baseUrl}/cli/addresses/streets?cityName=${cityName}&nation=${nation}&q=${query}`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
            mode: "cors",
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function nations(query) {
    return (
        fetch(`${config.baseUrl}/cli/addresses/nations?q=${query}`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
            mode: "cors",
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}