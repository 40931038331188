import React from "react"
import { Translation } from "react-i18next";
import { debounce } from "lodash"
import { addressesService } from "../../../services/basis/AddressesService";

import "./address-select.styles.scss";

export default class AddressDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            input: this.props.value ? this.props.value : "",
            items: null,
            selected: false
        }
    }

    // componentDidMount() {
    //     const { cityName, nation } = this.props
    //     if ((cityName && cityName !== "") &&
    //         (nation && nation !== "")) {
    //         addressesService.fetchStreets(cityName, nation, this.state.input)
    //             .then(response => {
    //                 console.log(response);
    //                 if (response.data && response.data.length > 0) {
    //                     this.setState({ items: response.data })
    //                 }
    //             }).catch(error => {
    //                 console.log(error)
    //             })
    //     }
    // }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.input !== this.state.input) {
            this.getItems(this.state.input);
        }
        if (prevProps.value !== this.props.value) {
            this.setState({ input: this.props.value })
        }
    }

    handleInput = (e) => {
        if (e.target.value !== "") {
            this.setState({ [e.target.name]: e.target.value, isOpen: true });
        } else {
            this.setState({ [e.target.name]: e.target.value, isOpen: false, selected: false });
            this.props.setAddress("")
        }
    }

    getDebounceItems = (input) => {
        if (this.state.input !== "") {
            const { cityName, nation } = this.props
            addressesService.fetchStreets(cityName, nation, this.state.input)
                .then(response => {
                    this.setState({ items: response.data })
                }).catch(error => {
                    console.log(error)
                })
        } else {
            this.setState({ items: null, isOpen: false, selected: false })
        }
    }

    getItems = debounce((input) => {
        this.getDebounceItems(input)
    }, 1000)


    componentWillUnmount() {
        document.removeEventListener('click', this.globalClickListener)
    }

    globalClickListener = () => {
        this.setState({ isOpen: false }, () => {
            document.removeEventListener('click', this.globalClickListener)
        })
    }

    toggleOpen = () => {
        this.setState(prevState => ({ isOpen: !prevState.isOpen }), () => {
            if (this.state.isOpen) {
                document.addEventListener('click', this.globalClickListener)
            }
        })
    }

    handleChange = (item) => {
        this.setState({
            isOpen: false,
            input: `${item}`,
            selected: true
        })
        this.props.setAddress(item)
    }

    render() {
        const { items, input, selected } = this.state;
        const menuClass = `dropdown-items ${this.state.isOpen ? "dropdown--isOpen" : ""} 
        dropdown-menu${this.state.isOpen ? " show" : ""}`;
        return (
            <Translation>
                {t =>
                    <div className="address-select">
                        <input
                            name="input"
                            placeholder={this.props.placeholder}
                            onChange={this.handleInput}
                            value={input}
                            type="search"
                            autoComplete="new-password"
                            className={`${this.props.errors ? "input-required" : ""}`}
                            disabled={this.props.disabled}
                            style={selected ? { cursor: "not-allowed" } : null}
                            onKeyDown={(e) => {
                                if (selected) {
                                    e.preventDefault();
                                }
                            }}
                        />
                        <div className={menuClass} aria-labelledby="dropdownMenuButton">
                            {items && items.map((item) => (
                                <span key={item} className="dropdown-item"
                                    onClick={() => this.handleChange(item)}>
                                    {item}
                                </span>
                            ))}
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}

