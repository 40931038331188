import config from '../../config/config';
import { handleResponse } from '../../utils/handleResponse';
import { checkResponse } from '../../utils/checkResponse';
import { format } from "date-fns";

export const authenticationService = { login, logout, signup, isLogged, updateUser, coopSignup, signupCheck, deleteUser };

function login(data) {
    let headers = new Headers();
    headers.set('Authorization', 'Basic ' + btoa(data.username + ":" + data.password));
    return (
        fetch(`${config.baseUrl}/cli/profiles/login`, {
            method: 'GET',
            headers: headers,
            mode: "cors",
            credentials: 'include'
        })
            .then(handleResponse)
            .then(checkResponse)
            .then((responseData) => {
                localStorage.setItem('user', JSON.stringify(responseData.data));
                return responseData;
            })
    );
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("user")
    return (
        fetch(`${config.baseUrl}/cli/profiles/logout`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            credentials: 'include'
        })
            .then(handleResponse)
        // .then(checkResponse)
    );
}

function signup(data, type) {
    console.log(data);
    return (
        fetch(`${config.baseUrl}/cli/profiles/signup`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            body: JSON.stringify({
                username: data.username,
                password: data.password,
                createRemote: type === "check" ? true : false,
                profile: {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    birthDate: data.birthDate,
                    birthNationId: data.birthNationId,
                    birthNation: data.birthNation,
                    birthCountyId : data.birthCountyId,
                    birthCounty : data.birthCounty,
                    birthCityId: data.birthCityId,
                    birthCity : data.birthCity,
                    mobilePhone: data.mobilePhone,
                    email: data.username,
                    gender: data.gender,
                    cards: (data.code && data.code !== null && data.code !== "") ?
                        [{
                            code: data.code,
                            principal: true,
                            enabled: true
                        }]
                        : null,
                    address: {
                        cityId: data.cityId,
                        city: data.city,
                        countyId: data.countyId,
                        county: data.county,
                        zip: data.zip,
                        address: data.address,
                        streetNumber: data.streetNumber,
                        nationId: data.nationId,
                        nation: data.nation
                    },
                    accept1: data.accept1,
                    accept2: data.accept2,
                    accept3: data.accept3,
                    accept4: data.accept4,
                },
            })
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function isLogged() {
    return (
        fetch(`${config.baseUrl}/cli/profiles/login`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            credentials: 'include'
        })
            .then(handleResponse)
            .then(checkResponse)
            .then((responseData) => {
                localStorage.setItem("user", JSON.stringify(responseData.data))
                return responseData;
            })
    );
}

function updateUser(data) {
    console.log(data);
    return (
        fetch(`${config.baseUrl}/cli/profiles`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                firstName: data.firstName,
                lastName: data.lastName,
                mobilePhone: data.mobilePhone,
                ssnCode: data.ssnCode,
                // birthDate: data.birthDate,
                // email: data.email,
                // gender: data.gender,
                cards: [{
                    code: data.profileCard,
                    principal: true,
                    enabled: true
                }],
                address: {
                    id: data.id ? data.id : null,
                    cityId: data.cityId,
                    city: data.city,
                    countyId: data.countyId,
                    county: data.county,
                    zip: data.zip,
                    address: data.address,
                    streetNumber: data.streetNumber,
                    nationId: data.nationId
                },
                accept1: data.accept1,
                accept2: data.accept2,
                accept3: data.accept3,
                accept4: data.accept4,
            })
        })
            .then(handleResponse)
            .then(checkResponse)
            .then((responseData) => {
                localStorage.setItem("user", JSON.stringify(responseData.data))
                return responseData;
            })
    );
}

function coopSignup(data) {
    return (
        fetch(`${config.baseUrl}/cli/profiles/signup`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            body: JSON.stringify({
                username: data.username,
                password: data.password,
                profile: {
                    accept1: data.accept1,
                    accept2: data.accept2,
                    accept3: data.accept3,
                    accept4: data.accept4,
                },
            })
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function signupCheck(data, type) {
    console.log(data);
    return (
        fetch(`${config.baseUrl}/cli/profiles/signupcheck`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            body: JSON.stringify({
                cardNumber: data.cardNumber,
                birthDate: format(data.birthDate, "yyyy-MM-dd"),
                firstName: data.firstName,
                lastName: data.lastName,
                "@type": type,
                birthCityId: data.birthCityId,
                birthCountyId: data.birthCountyId,
                birthNationId: data.birthNationId,
                gender: data.gender
            })
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function deleteUser() {
    return (
        fetch(`${config.baseUrl}/cli/profiles/deleteRequest`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            credentials: "include"
        })
            .then(handleResponse)
            .then(checkResponse)
            .then((responseData) => {
                return responseData;
            })
    );
}
