import React from "react"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CardProduct from "../../components/Products/CardProduct/CardProduct";
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from 'react-loader-spinner'
import Accordion from "react-bootstrap/Accordion"
import { Translation } from "react-i18next";
import { ecommerceService } from "../../services/ecommerce/EcommerceService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isMobile } from 'react-device-detect';
import { productImpression } from "../../components/GoogleTagManager";
import config from "../../config/config";
import { connect } from "react-redux"
// import Banner from "../../images/BannerCategoria_X2.jpg"
// import MobileCategoryBanner from "./MobileCategoryBanner";
// import CategoryBanner from "./CategoryBanner";
// import TabletCategoryBanner from "./TabletCategoryBanner";

class SearchCategory extends React.Component {
    constructor(props) {
        super(props);
        const storeId = this.props.cart ? this.props.cart.storeId : config.DEFAULT_STORE_ID;
        this.searchProducts = (input, page, categoryId, onlyPromotions, onlyBranded, tag) => {
            ecommerceService.searchProducts(input, storeId, page, categoryId, onlyPromotions, onlyBranded, tag)
                .then(response => {
                    this.setState({
                        categoryId: categoryId,
                        products: response.data,
                        totalPages: response.totalPages,
                        page: response.number,
                        hasMore: !response.last,
                    })
                    productImpression(response.data);
                }).catch((error) => {
                    console.log(error);
                })
        }

        const category = this.props.location.state && this.props.location.state.category
        const items = category && category.items;
        if (items) {
            items.sort(function (a, b) {
                return a.order - b.order
            })
            items.forEach(item => {
                if (item && item.items && item.items.length > 0) {
                    item.items.sort(function (a, b) {
                        return a.order - b.order
                    })
                }
            });
        }

        this.state = {
            categoryId: category ? category.id : "",
            category: category ? category : this.props.location.state.category,
            categoryTitle: category ? category.description : null,
            mainCategory: null,
            subCategory: null,
            products: [],
            page: 0,
            totalPages: null,
            hasMore: true,
            tags: [],
            onlyPromotions: false,
            onlyBranded: false,
            isTag: this.props.location.state?.isTag,
            tagDescription: this.props.location.state?.tagDescription,
            tag: this.props.location.state.tag ? Array.isArray(this.props.location.state.tag) ? this.props.location.state.tag : [this.props.location.state.tag] : [],
            showCategories: isMobile ? false : true,
            activeKey: this.props.location.state.activeKey ? this.props.location.state.activeKey : null
        }
    }

    updateDimensions() {
        if (window.innerWidth >= 768) this.setState({ showCategories: true })
    }

    componentDidMount() {
        this.updateDimensions();
        ecommerceService.productsTags()
            .then(response => {
                this.setState({
                    tags: response
                })
            })
            .catch(error => {
                console.log(error)
            })
        window.addEventListener("resize", this.updateDimensions.bind(this));
        if (this.props.location.state.from && this.props.location.state.from === 'product') {
            const { categoryId, category, mainCategory, subCategory, categoryTitle, tag } = this.props.location.state
            this.setState({ categoryId, category, categoryTitle, mainCategory, subCategory }, () => {
                let _categoryId
                if (subCategory) {
                    _categoryId = subCategory
                    this.setState({ showCategories: true })
                } else if (mainCategory) _categoryId = mainCategory
                else _categoryId = categoryId
                this.searchProducts("", this.state.page, _categoryId, this.state.onlyPromotions, this.state.onlyBranded, tag);
            })
        } else {
            if (!this.state.isTag) {
                this.searchProducts("", this.state.page, this.state.categoryId, this.state.onlyPromotions, this.state.onlyBranded, "");
            } else {
                this.searchProducts("", this.state.page, this.state.categoryId, this.state.onlyPromotions, this.state.onlyBranded, this.state.tag);
                this.setState({
                    categoryTitle: this.state.tagDescription
                })
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.state.category) {
            const items = nextProps.location.state.category.items;
            if (items) {
                items.sort(function (a, b) {
                    return a.order - b.order
                })
                items.forEach(item => {
                    if (item && item.items && item.items.length > 0) {
                        item.items.sort(function (a, b) {
                            return a.order - b.order
                        })
                    }
                });
            }
            const categoryId = nextProps.location.state.category.id;
            if (this.state.isTag) {
                this.searchProducts("", 0, categoryId, this.state.onlyPromotions, this.state.onlyBranded, "")
                this.setState({
                    categoryId: categoryId,
                    category: nextProps.location.state.category,
                    categoryTitle: nextProps.location.state.category.description,
                    isTag: false,
                    tag: []
                });
            } else {
                if (categoryId !== this.state.category.id) {
                    this.searchProducts("", 0, categoryId, this.state.onlyPromotions, this.state.onlyBranded, "")
                    this.setState({
                        categoryId: categoryId,
                        category: nextProps.location.state.category,
                        categoryTitle: nextProps.location.state.category.description,
                        mainCategory: null,
                        subCategory: null,
                        activeKey: null
                    })
                }
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.onlyPromotions !== prevState.onlyPromotions ||
            this.state.onlyBranded !== prevState.onlyBranded) {
            this.searchProducts("", 0, this.state.categoryId, this.state.onlyPromotions, this.state.onlyBranded, this.state.tag)
        }
    }

    changeCategory = (categoryId, title, index) => {
        if (categoryId === this.state.mainCategory) {
            this.searchProducts("", 0, this.state.category.id, this.state.onlyPromotions, this.state.onlyBranded, this.state.tag)
            this.setState({
                categoryId: this.state.category.id,
                mainCategory: null,
                subCategory: null,
                categoryTitle: this.state.category.description,
                activeKey: null
            })
        } else if (categoryId !== this.state.categoryId) {
            this.searchProducts("", 0, categoryId, this.state.onlyPromotions, this.state.onlyBranded, this.state.tag)
            this.setState({
                categoryId: categoryId,
                mainCategory: categoryId,
                subCategory: null,
                categoryTitle: title,
                activeKey: index
            })
        }
    }

    changeSubCategory = (categoryId, title) => {
        if (categoryId !== this.state.categoryId) {
            this.searchProducts("", 0, categoryId, this.state.onlyPromotions, this.state.onlyBranded, this.state.tag)
            this.setState({
                categoryId: categoryId,
                subCategory: categoryId,
                categoryTitle: title
            })
        }
    }

    loadMore = () => {
        const storeId = this.props.cart ? this.props.cart.storeId : config.DEFAULT_STORE_ID;
        const page = this.state.page + 1;
        if (page < this.state.totalPages) {
            ecommerceService.searchProducts("", storeId, page, this.state.categoryId, this.state.onlyPromotions, this.state.onlyBranded, this.state.tag)
                .then(response => {
                    const newResponse = this.state.products;
                    newResponse.push(...response.data);
                    this.setState({
                        products: newResponse,
                        totalPages: response.totalPages,
                        page: response.number,
                        hasMore: !response.last
                    })
                    productImpression(response.data);
                }).catch((error) => {
                    console.log(error);
                })
        }
    }

    onChange = (e) => this.setState({ [e.target.name]: e.target.checked });

    setTag = (e) => {
        const tags = this.state.tag;
        if (tags.includes(e.target.name)) {
            const index = tags.indexOf(e.target.name);
            if (index > -1) {
                tags.splice(index, 1);
            }
            this.setState({ tag: tags });
        } else {
            tags.push(e.target.name)
            this.setState({ tag: tags })
        }
        this.searchProducts("", 0, this.state.categoryId, this.state.onlyPromotions, this.state.onlyBranded, tags)
    }

    render() {
        const { products, hasMore, categoryTitle, showCategories, mainCategory, subCategory, activeKey, tags, tag } = this.state;
        const category = this.props.location.state.category;
        return (
            <>
                <Translation>
                    {t =>
                        <div className="mobile-view-search "
                            style={{ backgroundColor: "#fbfbfb", minHeight: "400px" }}>
                            {/* Only for mobile */}
                            {/* <MobileCategoryBanner categoryId={category && category.id} className="category-banner d-md-none mb-4" /> */}

                            {/* {this.state.isTag &&
                                <CategoryBanner categoryId={tag} className="category-banner d-md-none mb-4" />
                            } */}
                            {/*<div className="category-banner" style={{ backgroundImage: `url(${Banner})` }} />*/}
                            <Row className="category-container container-fluid">
                                <Col md={5} lg={4}>
                                    <div className="category-tree">
                                        <div className="category-align"><span className="category-all"
                                            onClick={() => { document.getElementById('category').click() }}>
                                            Vedi tutte le categorie
                                        </span></div>
                                        <span className="category-name">
                                            {categoryTitle}
                                        </span>
                                        <hr />
                                        <Accordion className="category-subcategory" activeKey={activeKey}>
                                            <div className="accordion-categories d-md-none">
                                                <nobr>
                                                    <span className="category-title"
                                                        onClick={() => this.setState({ showCategories: !showCategories })}>{t("subCategory")}
                                                    </span>
                                                    <span className="icon pl-1"><FontAwesomeIcon style={{ height: "15px" }}
                                                        icon={showCategories ? "chevron-down" : "chevron-right"} />
                                                    </span>
                                                </nobr>
                                            </div>
                                            {showCategories &&
                                                <ul>
                                                    {category && category.items && category.items.map((item, index) => (
                                                        <>
                                                            <div className="category-sidebar" key={item.id}>
                                                                <Accordion.Toggle as="li" eventKey={index}
                                                                    onClick={() => this.changeCategory(item.id, item.description, index)}>
                                                                    <span className={`category-sidebar-option 
                                                            ${item.id === this.state.mainCategory ? "category-sidebar-option-selected" : ""}`}>
                                                                        {item.description}
                                                                    </span>
                                                                </Accordion.Toggle>
                                                                <Accordion.Collapse eventKey={index}>
                                                                    <nav className={`category-sidebar-sub ${item.items ? "" : "d-none"}`}>
                                                                        {item.items && item.items.map((subcategory, index) => (
                                                                            <li key={subcategory.id} onClick={() => this.changeSubCategory(subcategory.id, subcategory.description)}>
                                                                                <span className={`category-sidebar-sub-option 
                                                                            ${subcategory.id === this.state.subCategory ? "category-sidebar-sub-option-selected" : ""}`}>
                                                                                    {subcategory.description}
                                                                                </span>
                                                                            </li>
                                                                        ))}
                                                                    </nav>
                                                                </Accordion.Collapse>
                                                            </div>
                                                            <hr />
                                                        </>
                                                    ))}
                                                </ul>
                                            }
                                        </Accordion>
                                    </div>
                                </Col>
                                <Col md={7} lg={8}>
                                    {/* Only for tablet */}
                                    {/* <TabletCategoryBanner categoryId={category && category.id} className="category-banner d-none d-md-block d-lg-none" /> */}

                                    {/* Only for desktop */}
                                    {/* <CategoryBanner categoryId={category && category.id} className="category-banner d-none d-lg-block" /> */}

                                    {/* {this.state.isTag &&
                                        <CategoryBanner categoryId={tag} className="category-banner d-none d-md-block" />
                                    } */}
                                    <div className="category-wrapper">
                                        <div className="container-fluid">
                                            <Row>
                                                <div className="custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input"
                                                        value={this.state.onlyPromotions}
                                                        id="onlyPromotions" name="onlyPromotions"
                                                        onChange={this.onChange}
                                                    />
                                                    <label className="custom-control-label mobile-checkbox" htmlFor="onlyPromotions">
                                                        {t("onlyPromotions")}
                                                    </label>
                                                </div>
                                                <div className="custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input"
                                                        value={this.state.onlyBranded}
                                                        id="onlyBranded" name="onlyBranded"
                                                        onChange={this.onChange}
                                                    />
                                                    <label className="custom-control-label mobile-checkbox" htmlFor="onlyBranded">
                                                        {t("onlyBranded")}
                                                    </label>
                                                </div>

                                                {!this.state.isTag && tags &&
                                                    tags.map(category => (
                                                        <div key={category.id} className="custom-checkbox mr-5">
                                                            <input type="checkbox" className="custom-control-input"
                                                                id={category.id}
                                                                name={category.id}
                                                                value={category.id}
                                                                checked={tag.includes(category.id)}
                                                                onChange={this.setTag}
                                                            />
                                                            <label
                                                                className="custom-control-label mobile-checkbox" htmlFor={category.id}>
                                                                {category.description}
                                                            </label>
                                                        </div>
                                                    ))}
                                            </Row>
                                        </div>
                                    </div>

                                    <InfiniteScroll
                                        style={{ maxWidth: "100%", overflowX: "hidden" }}
                                        dataLength={products.length}
                                        next={this.loadMore}
                                        hasMore={hasMore}
                                        loader=
                                        {<Loader
                                            type="ThreeDots"
                                            color="#333"
                                            height={70}
                                            width={70}
                                            timeout={3000} //3 secs
                                        />}
                                    >
                                        {products && products.length > 0 &&
                                            <Row className="d-flex search-row-handling">
                                                {products.map((item) => (
                                                    <Col className="search-col-handling" key={item.id} >
                                                        <CardProduct
                                                            category={category}
                                                            categoryId={this.state.categoryId}
                                                            tag={this.state.tag}
                                                            isTag={this.state.isTag}
                                                            mainCategory={mainCategory}
                                                            categoryTitle={categoryTitle}
                                                            subCategory={subCategory}
                                                            from={"product"}
                                                            activeKey={activeKey}
                                                            colStyle={true}
                                                            item={item}
                                                            style={{ width: "unset", margin: "0 0 50px 0" }} />
                                                    </Col>
                                                ))}
                                            </Row>
                                        }
                                        {products && products.length === 0 &&
                                            <div className="d-flex justify-content-center">
                                                <span className="title">{t("searchCategory.noItemsPresent")}</span>
                                            </div>
                                        }
                                    </InfiniteScroll>

                                </Col>
                            </Row>
                        </div>
                    }
                </Translation>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        cart: state.cart.cart,
    };
}

export default connect(mapStateToProps)(SearchCategory);