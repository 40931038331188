import React from "react";
import { ReactComponent as FruttaVerdura } from '../../images/categories/Frutta e Verdura.svg';
import { ReactComponent as Carne } from '../../images/categories/Carne.svg';
import { ReactComponent as AcquaBibite } from '../../images/categories/Acqua e bibite.svg';
// import { ReactComponent as AlimentariConfezionati } from '../../images/categories/Alimenti confezionati.svg';
import { ReactComponent as Extralimentare } from '../../images/categories/Extralimentare.svg';
import { ReactComponent as PanePasticceria } from '../../images/categories/Pane e dolci.svg';
import { ReactComponent as SalumiFormaggi } from '../../images/categories/Latticini, salumi e formaggi.svg';
import { ReactComponent as Freschi } from '../../images/categories/Freschi.svg';
import { ReactComponent as ColazioneSnack } from '../../images/categories/Colazione e Snack.svg';
// import { ReactComponent as Surgelati } from '../../images/categories/Surgelati.svg';
import { ReactComponent as Surgelati } from '../../images/categories/Surgelati_1.svg';
import { ReactComponent as VinoBirre } from '../../images/categories/Vino, birre e liquori.svg';
import { ReactComponent as Pesce } from '../../images/categories/Pesce.svg';
import { ReactComponent as CuraPersona } from '../../images/categories/Cura persona.svg';
import { ReactComponent as CuraCasa } from '../../images/categories/Cura casa.svg';
// import { ReactComponent as Animali } from '../../images/categories/pawprint.svg';
import { ReactComponent as Animali } from '../../images/categories/Animali_1.svg';
import { ReactComponent as Elettronica } from '../../images/categories/Elettronica.svg';
import { ReactComponent as Ricorrenze } from '../../images/categories/Ricorrenze.svg';

export default class CategoryIcon extends React.Component {
    render() {
        let icon = null;
        const { category } = this.props;
        console.log(category);
        switch (category) {
            case "ORTOFRUTTA": icon = <FruttaVerdura className="category-icon" />;
                break;
            case "CARNI": icon = <Carne className="category-icon" />;
                break;
            case "DROGHERIA ALIMENTARE": icon = <PanePasticceria className="category-icon" />;
                break;
            case "BEVANDE": icon = <AcquaBibite className="category-icon" />;
                break;
            case "EXTRALIMENTARE": icon = <Extralimentare className="category-icon" />;
                break;
            case "LATTICINI, SALUMI E FORMAGGI": icon = <SalumiFormaggi className="category-icon" />;
                break;
            case "FRESCO": icon = <Freschi className="category-icon" />;
                break;
            case "COLAZIONE, SNACK E INTEGRATORI": icon = <ColazioneSnack className="category-icon" />;
                break;
            case "FREDDO": icon = <Surgelati className="category-icon" />;
                break;
            case "": icon = <VinoBirre className="category-icon" />;
                break;
            case "PESCE": icon = <Pesce className="category-icon" />;
                break;
            case "CURAPERSONA": icon = <CuraPersona className="category-icon" />;
                break;
            case "CURACASA": icon = <CuraCasa className="category-icon" />;
                break;
            case "PETCARE": icon = <Animali className="category-icon" />;
                break;
            case "ELETTRONICA E FAI DA TE": icon = <Elettronica className="category-icon" />;
                break;
            case "RICORRENZE E COLLECTION": icon = <Ricorrenze className="category-icon" />;
                break;
            case "Nessuna classificazione": icon = <span style={{marginRight: "45px"}} className="category-icon">&nbsp;</span>;
                break;


            default: return icon;
        }

        return icon;
    }
}




