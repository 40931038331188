import React from "react"
import AddressDropdown from "./AddressDropdown/AddressDropdown"
import { Link } from "react-router-dom"
import { addressesService } from "../../ProfileContainer/ProfileAddresses/Service"
import { Translation } from "react-i18next";
import toaster from "../../../utils/toaster";

export default class AddressSelect extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            addresses: [],
            isLoaded: false
        }
    }

    componentDidMount() {
        addressesService.getAddresses()
            .then(response => {
                console.log(response);
                this.setState({
                    addresses: response.data,
                    isLoaded: true
                })
            }).catch((error) => {
                console.log(error);
            })
    }

    newAddress = (address) => {
        addressesService.newAddress(address)
            .then(response => {
                console.log(response);
                this.setState({
                    addresses: response.data,
                })
                if (address.id) {
                    toaster.standard("Indirizzo di spedizione aggiornato con successo!")
                } else {
                    toaster.standard("Indirizzo di spedizione aggiunto con successo!")
                }
            }).catch((error) => {
                console.log(error);
            })
    }

    render() {
        const { select, label, showAdd, setShowAdd } = this.props;
        const { addresses, isLoaded } = this.state;
        if (isLoaded) {
            if (addresses.length > 0) {
                return (
                    <AddressDropdown
                        items={addresses}
                        select={select}
                        label={label}
                        setShowAdd={setShowAdd}
                        showAdd={showAdd}
                        newAddress={this.newAddress}
                    />
                );
            } else {
                return (
                    <Translation>
                        {t =>
                            <Link to="/profile/addresses">
                                <button className="dropdown-button dropdown-button-link outline mb-3">
                                    <span>{t("addressDropdown.addDeliveryAddress")}</span>
                                </button>
                            </Link>
                        }
                    </Translation>
                );
            }
        } else {
            return null;
        }
    }
}

