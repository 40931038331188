
import { handleResponse } from '../../../utils/handleResponse';
import { checkResponse } from '../../../utils/checkResponse';
import config from '../../../config/config';

export const addressesService = {
    getAddresses, newAddress, deleteAddress, getBillingAddresses, newBillingAddress, deleteBillingAddress
};

function getAddresses() {
    return (
        fetch(`${config.baseUrl}/cli/profiles/addresses`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            mode: "cors",
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function newAddress(data) {
    let body;
    if (data.id) {
        body = {
            id: data.id,
            address: data.address,
            state: data.state,
            city: data.city,
            cityId: data.cityId,
            county: data.county,
            countyId: data.countyId,
            zip: data.zip,
            streetNumber: data.streetNumber,
            nation: "IT"
        }
    } else {
        body = {
            address: data.address,
            state: data.state,
            city: data.city,
            cityId: data.cityId,
            county: data.county,
            countyId: data.countyId,
            zip: data.zip,
            streetNumber: data.streetNumber,
            nation: "IT"
        }
    }

    return (
        fetch(`${config.baseUrl}/cli/profiles/addresses`, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            mode: "cors",
            body: JSON.stringify(body)
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function deleteAddress(addressId) {
    return (
        fetch(`${config.baseUrl}/cli/profiles/addresses/delete`, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            mode: "cors",
            body: JSON.stringify({
                id: addressId,
            })
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function getBillingAddresses() {
    return (
        fetch(`${config.baseUrl}/cli/profiles/billing/addresses`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            mode: "cors",
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function newBillingAddress(data) {
    
    // private String customerCode;
    // private String subject;
    // private String name;

    let body;
    if (data.id) {
        body = {
            id: data.id,
            address: data.address,
            city: data.city,
            county: data.county,
            zip: data.zip,
            streetNumber: data.streetNumber,
            nation: "IT",
            vatCode: data.vatCode,
            ssnCode: data.ssnCode,
            companyName: data.companyName,
            phone: data.phone,
            fax: data.fax,
            pec: data.pec,
            recipientCode: data.recipientCode
        }
    } else {
        body = {
            address: data.address,
            city: data.city,
            county: data.county,
            zip: data.zip,
            streetNumber: data.streetNumber,
            nation: "IT",
            vatCode: data.vatCode,
            ssnCode: data.ssnCode,
            companyName: data.companyName,
            phone: data.phone,
            fax: data.fax,
            pec: data.pec,
            recipientCode: data.recipientCode
        }
    }

    return (
        fetch(`${config.baseUrl}/cli/profiles/billing/addresses`, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            mode: "cors",
            body: JSON.stringify(body)
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function deleteBillingAddress(addressId) {
    return (
        fetch(`${config.baseUrl}/cli/profiles/billing/addresses/delete`, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            mode: "cors",
            body: JSON.stringify({
                id: addressId,
            })
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

