import React from "react"
import { Translation } from "react-i18next";
import AddressPopupCreate from "../../../ProfileContainer/ProfileAddresses/AddressPopup/AddressPopupCreate";

export default class Dropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            btnTitle: this.props.label,
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.globalClickListener)
    }

    globalClickListener = () => {
        this.setState({ isOpen: false }, () => {
            document.removeEventListener('click', this.globalClickListener)
        })
    }

    toggleOpen = () => {
        this.setState(prevState => ({ isOpen: !prevState.isOpen }), () => {
            if (this.state.isOpen) {
                document.addEventListener('click', this.globalClickListener)
            }
        })
    }

    handleChange = (item) => {
        this.setState({
            isOpen: false,
            btnTitle: `${item.address} ${item.streetNumber}, ${item.city}`
        })
        this.props.select(item)
    }

    render() {
        const { items, showAdd, setShowAdd, newAddress } = this.props;
        const menuClass = `dropdown-items ${this.state.isOpen ? "dropdown--isOpen" : ""} dropdown-menu${this.state.isOpen ? " show" : ""}`;
        return (
            <Translation>
                {t =>
                    <>

                        <div className="dropdown mb-3" >
                            <button className="dropdown-button outline dropdown-toggle" onClick={this.toggleOpen} type="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {this.state.btnTitle}
                            </button>
                            <div className={menuClass} aria-labelledby="dropdownMenuButton">
                                <span className="dropdown-item dropdown-item-add" onClick={() => setShowAdd(true)}>
                                    {t("addressDropdown.addDeliveryAddress")}
                                </span>
                                {items.map((item) => (
                                    <span key={item.id} className="dropdown-item" onClick={() => this.handleChange(item)}>
                                        {item.address} {item.streetNumber}, {item.city}
                                    </span>
                                ))}
                            </div>
                        </div>
                        <AddressPopupCreate
                            action={newAddress}
                            title={t("addresses.new")}
                            button={t("save")}
                            isUpdate={false}
                            open={showAdd}
                            setShowAdd={setShowAdd}
                        />
                    </>
                }
            </Translation>
        );
    }
}

