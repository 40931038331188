import React from "react";
import Counter from "../../../../components/UI/Counter/Counter";
import Placeholder from "../../../../images/product-placeholder.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom"

import "./smartlist-item.styles.scss"

class SmartlistItem extends React.Component {
    render() {
        const { item, currentSlist, itemDelete, itemUpdate } = this.props;
        return (
            <div className="row mb-3 favorite-products">
                <div className="col-3 col-sm-1">
                    <Link className="link"
                        to={{ pathname: `/product/${item.barcode}`, state: { product: item } }}>
                        <img src={item.imageUrl ? item.imageUrl : Placeholder} alt="image"
                            onError={(e) => { e.target.src = Placeholder }}
                        />
                    </Link>
                </div>

                <div className="col-7 col-sm-5 favorite-products-text">
                    <Link className="link"
                        to={{ pathname: `/product/${item.barcode}`, state: { product: item } }}>
                        <p className="cart-product-description">{item.description}</p>
                    </Link>
                </div>
                <div className="col-2 col-sm-2 favorite-products-text d-none d-sm-block">
                    <Counter
                        value={item.amount}
                        increment={() => itemUpdate(currentSlist.id, item.id, item.amount + 1)}
                        decrement={() => item.amount !== 1 && itemUpdate(currentSlist.id, item.id, item.amount - 1)}
                    />
                </div>
                <div className="col-1 col-sm-2 favorite-products-text">
                    <button className="button-clean outline primary-text ml-3"
                        onClick={() => itemDelete(currentSlist.id, item.id)}>
                        <FontAwesomeIcon icon="times-circle" size="1x" />
                    </button>
                </div>
            </div>
        );
    }
}

export default SmartlistItem;
