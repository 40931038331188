import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'
import Popup from "reactjs-popup";
import { Translation } from "react-i18next";
import ValidateInput from "../../../../components/UI/Input/ValidateInput";
import AddressCounty from "../../../../components/UI/AddressSelect/AddressCounty";
import AddressCity from "../../../../components/UI/AddressSelect/AddressCity";
import AddressZip from "../../../../components/UI/AddressSelect/AddressZip";
import AddressDropdown from "../../../../components/UI/AddressSelect/AddressDropdown";
import AddressNation from "../../../../components/UI/AddressSelect/AddressNation";

import "./address-popup.styles.scss";

function AddressPopup(props) {

    const { register, handleSubmit, errors, watch, setValue } = useForm({
        mode: 'onBlur',
    });

    const address = props?.address;
    const [nation, setNationValue] = useState(address ? address.nation : null);
    const [county, setCountyValue] = useState(address ? address.county : null);
    const [city, setCityValue] = useState(address ? address.city : null);
    const [nationId, setNationId] = useState(address ? address.nationId : null);
    const [countyId, setCountyId] = useState(address ? address.countyId : null);
    const [cityId, setCityId] = useState(address ? address.cityId : null);
    const [zip, setZip] = useState(address ? address.zip : null);
    const [deliveryAddress, setDeliveryAddress] = useState(address ? address.address : null);
    const [isForeign, setIsForeign] = useState();
    const [foreignNation, setForeignNation] = useState();

    useEffect(() => {
        register(
            { name: "nationId" },
            { required: "Campo obbligatorio" }
        )
        register(
            { name: "countyId" },
            { required: "Campo obbligatorio" }
        )
        register(
            { name: "cityId" },
            { required: "Campo obbligatorio" }
        )
        register(
            { name: "zip" },
            { required: "Campo obbligatorio" }
        )
        register(
            { name: "address" },
            { required: "Campo obbligatorio" }
        )
        register({ name: "city" })
        register({ name: "county" })
        register({ name: "nation" })

        setValue("nationId", nationId);
        setValue("countyId", countyId);
        setValue("cityId", cityId);
        setValue("zip", zip);
        setValue("address", deliveryAddress);

        setValue("nation", nation);
        setValue("city", city);
        setValue("county", county);
    }, [register, nation, county, city, nationId, countyId, cityId, zip, deliveryAddress, setValue])

    const setNation = item => {
        setValue("nationId", item.id);
        setValue("nation", item.name);
        setNationId(item.id)
        setNationValue(item.name)
        setForeignNation(item.foreignNation);

        setCountyId(null);
        setCountyValue("");
        setCityId(null)
        setCityValue("")
        setAddress("")
        setZip("")
    }

    const setCounty = item => {
        setValue("countyId", item.id);
        setValue("county", item.name);
        setCountyId(item.id);
        setCountyValue(item.name);

        // reset also city and zip
        setCityId(null)
        setCityValue("")
        setZip("")
        setAddress("")
    }

    const setCity = item => {
        setValue("cityId", item.id);
        setCityId(item.id);
        if (item.foreignCity) {
            setValue("cityId", item.name);
            setCityId(item.name);
            setIsForeign(true)
        } else {
            setValue("cityId", item.id);
            setCityId(item.id);
            setIsForeign(false)
        }
        setValue("city", item.name);
        setCityValue(item.name)
        setAddress("")
        setZipCode("")
    }

    const setZipCode = item => {
        setValue("zip", item);
        setZip(item);
    }

    const setAddress = item => {
        setValue("address", item);
        setDeliveryAddress(item);
    }

    const onSubmit = data => {
        console.log(data);
        props.action(data);
        document.getElementById("close-popup").click();
    }

    return (
        <Translation>
            {t =>
                <Popup lockScroll={true} contentStyle={popupStyle} trigger={props.trigger} modal>
                    {close => (
                        <div className="address-popup">
                            <div id="close-popup" className="close" onClick={close}>
                                &times;
                            </div>
                            <div className="address-popup-header">
                                {props.title}
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off"
                                className={`address-popup-form needs-validation ${errors ? "had-validation" : ""}`}>
                                <div className="address-popup-content">
                                    <input autoComplete="false" name="hidden" type="text" style={{ display: 'none' }} />
                                    <div className="row popup-row">
                                        <div className="col-xs-6 col-sm-4 mb-3 mb-sm-0">
                                            <AddressNation
                                                placeholder="Nazione*"
                                                setNation={setNation}
                                                value={nation}
                                                errors={errors.nationId}
                                            />
                                        </div>
                                        <div className="col-xs-6 col-sm-4 mb-3 mb-sm-0">
                                            <AddressCounty
                                                placeholder="Provincia*"
                                                setCounty={setCounty}
                                                value={county}
                                                errors={errors.countyId}
                                                disabled={!nationId}
                                                nationId={nationId}
                                                foreignNation={foreignNation}
                                            />
                                        </div>
                                        <div className="col-xs-6 col-sm-4 mb-3 mb-sm-0">
                                            <AddressCity
                                                placeholder="Comune*"
                                                setCity={setCity}
                                                countyId={countyId}
                                                value={city}
                                                errors={errors.cityId}
                                                disabled={!countyId}
                                                nationId={nationId}
                                            />
                                        </div>
                                        {props.isUpdate &&
                                            <div className="d-none">
                                                <ValidateInput
                                                    name="id"
                                                    type="text"
                                                    value={props.address && props.address.id}
                                                    watch={watch("id")}
                                                    errors={errors.id}
                                                    register={register({
                                                        required: "true"
                                                    })}
                                                />
                                            </div>
                                        }
                                    </div>
                                    <div className="row popup-row">
                                        <div className="col-xs-6 col-sm-4 mb-3 mb-sm-0">
                                            {isForeign ?
                                                <ValidateInput
                                                    name="zip"
                                                    label="Cap*"
                                                    type="text"
                                                    watch={watch("zip")}
                                                    pattern="[0-9]"
                                                    maxLength={5}
                                                    value={zip}
                                                    disabled={!cityId}
                                                    errors={errors.zip}
                                                    register={register({
                                                        required: "Campo obbligatorio",
                                                    })}
                                                    onChange={(e) => setZipCode(e.target.value)}
                                                />
                                                :
                                                <AddressZip
                                                    placeholder="Cap*"
                                                    cityId={cityId}
                                                    setZip={setZipCode}
                                                    value={zip}
                                                    disabled={!cityId}
                                                    errors={errors.zip}
                                                />
                                            }
                                        </div>
                                        <div className="col-sm-6 mb-3 mb-sm-0">
                                            <AddressDropdown
                                                placeholder="Indirizzo*"
                                                setAddress={setAddress}
                                                nation={nation}
                                                cityName={city}
                                                value={deliveryAddress}
                                                disabled={!city}
                                            />
                                        </div>
                                        <div className="col-xs-6 col-sm-2 mb-3 mb-sm-0">
                                            <ValidateInput
                                                name="streetNumber"
                                                label="Civico*"
                                                type="text"
                                                value={props.address && props.address.streetNumber}
                                                watch={watch("streetNumber")}
                                                errors={errors.streetNumber}
                                                disabled={!city}
                                                register={register({
                                                    required: "true"
                                                })}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="address-popup-actions">
                                    {props.isUpdate &&
                                        <button className="cta delete" onClick={() => {
                                            props.deleteAddress(props.address && props.address.id)
                                            close();
                                        }}>
                                            <span>{t("addresses.delete")}</span>
                                        </button>
                                    }
                                    <button type="submit" className="cta">
                                        <span>{props.button}</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    )}
                </Popup>
            }
        </Translation>
    );
}

const popupStyle = {
    borderRadius: "15px"
}

export default AddressPopup;