import { handleResponse } from '../../utils/handleResponse';
import { checkResponse } from '../../utils/checkResponse';
import config from '../../config/config';

export const storesService = {
    stores, states, storesByDelivery, storesCoordinate, storesByZipcode
};

function stores(page, query) {
    return (
        fetch(`${config.baseUrl}/cli/stores/ecommerce?s=${config.maxSize}&p=${page}&q=${query}`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
            mode: "cors",
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function storesCoordinate(page, lat, lon, query) {
    return (
        fetch(`${config.baseUrl}/cli/stores/ecommerce?s=${config.maxSize}&p=${page}&lat=${lat}&lon=${lon}&q=${query}`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
            mode: "cors",
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function states() {
    return (
        fetch(`${config.baseUrl}/cli/stores/states`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
            mode: "cors",
        })
            .then(handleResponse)
        //.then(checkResponse)
    );
}

function storesByDelivery(zipCode, deliveryMode) {
    return (
        fetch(`${config.baseUrl}/cli/stores/ecommerce/delivery?delivery=${deliveryMode}&zipCode=${zipCode}`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
            mode: "cors",
        })
            .then(handleResponse)
        // .then(checkResponse)
    );
}

function storesByZipcode(zipcode) {
    return (
        fetch(`${config.baseUrl}/cli/stores/ecommerce?zipcode=${zipcode}`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
            mode: "cors",
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}