import React from "react";
import Slider from "react-slick";
import HomeTag from "./HomeTag/HomeTag";
import { ReactComponent as SenzaGlutine } from "../../../images/tags/senza glutine.svg";
import { ReactComponent as Biologico } from "../../../images/tags/biologico.svg";
import { ReactComponent as Benessere } from "../../../images/tags/benessere.svg";
import { ReactComponent as SenzaLattosio } from "../../../images/tags/senza lattosio.svg";
import { ReactComponent as Vegano } from "../../../images/tags/vegano.svg";
import { ReactComponent as SiConTe } from "../../../images/tags/esclusivo_siconte.svg";
import { ReactComponent as Offerta } from "../../../images/tags/offerta.svg";
import { ReactComponent as Territorio } from "../../../images/tags/territorio.svg";
import { ecommerceService } from "../../../services/ecommerce/EcommerceService";
import { withRouter } from "react-router-dom";


class HomeCategories extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tags: []
        }
    }

    componentDidMount() {
        ecommerceService.productsTags()
            .then(response => {
                this.setState({
                    tags: response
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    renderIcon = (id) => {
        switch (id) {
            case "Bio":
                return <Biologico />
            case "Senza_glutine":
                return <SenzaGlutine />
            case "Senza_lattosio":
                return <SenzaLattosio />
            case "Benessere":
                return <Benessere />
            case "Vegano":
                return <Vegano />
            case "Territorio":
                return <Territorio />

            default:
                return null;
        }
    }

    render() {
        const { tags } = this.state;
        return (
            <div className="container-fluid home-categories">
                <Slider {...settings}>
                    <div className="home-categories-item">
                        <div className="text-center content">
                            <div className="home-categories-link" onClick={() => {
                                this.props.history.push({
                                    pathname: "/search",
                                    state: { searchInput: "", onlyPromotions: true }
                                })
                            }}>
                                <Offerta />
                                <div className="text">In offerta</div>
                            </div>
                        </div>
                    </div>
                    <div className="home-categories-item">
                        <div className="text-center content">
                            <div className="home-categories-link" onClick={() => {
                                this.props.history.push({
                                    pathname: "/search",
                                    state: { searchInput: "", onlyBranded: true }
                                })
                            }}>
                                <SiConTe />
                                <div className="text">A marchio</div>
                            </div>
                        </div>
                    </div>
                    {tags && tags.map(category => (
                        <HomeTag key={category.id} tag={category.id} description={category.description}>
                            {this.renderIcon(category.id)}
                        </HomeTag>
                    ))}
                </Slider>
            </div>
        );
    }
}

const settings = {
    //dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1301,
            settings: {
                slidesToShow: 6,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
                dots: true,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                dots: true,
                autoplay: true,
                infinite: true,
                autoplaySpeed: 5000
            }
        }
    ]
};

export default withRouter(HomeCategories);
