import React from 'react'
import { ReactComponent as SignupCardIcon } from "../../../images/signup-card.svg"
import { ReactComponent as SignupNoCardIcon } from "../../../images/signup-nocard.svg"
import { Translation } from 'react-i18next';

import "./signup-choose.styles.scss"

export default function SignupChoose({ setType }) {
    return (
        <Translation>
            {t =>
                <div className="signup-choose">
                    <div className="signup-choose-card">
                        <div className="icon">
                            <SignupNoCardIcon title="Con carta fedeltà" />
                        </div>
                        <div className="card-content">
                            <div className="title">Registrati utilizzando la tessera</div>
                            <div className="subtitle">
                                Sei già in possesso della nostra carta fedeltà?
                                Clicca qui per registrati
                            </div>
                            <button className="cta" type="submit" onClick={() => setType("fetch")}>
                                <span>{t("signup.register")}</span>
                            </button>
                        </div>
                    </div>
                    <div className="signup-choose-card">
                        <div className="icon">
                            <SignupCardIcon title="Senza carta fedeltà" />
                        </div>
                        <div className="card-content">
                            <div className="title">Registrati e crea la tua fidelity</div>
                            <div className="subtitle">
                                Registrati al nostro sito e crea la tua fidelity card che potrai utilizzare in tutti i nostri store.
                            </div>
                            <button className="cta" type="submit" onClick={() => setType("check")}>
                                <span>{t("signup.register")}</span>
                            </button>
                        </div>
                    </div>
                </div>
            }
        </Translation>
    )
}
