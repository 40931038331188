import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SignupDatepicker from "../SignupDatepicker";
import ValidateInput from '../../../components/UI/Input/ValidateInput';
import { Translation } from 'react-i18next';
import AddressCounty from '../../../components/UI/AddressSelect/AddressCounty';
import AddressCity from '../../../components/UI/AddressSelect/AddressCity';
import { ReactComponent as Alert } from "../../../images/card-alert.svg"
import AddressNation from '../../../components/UI/AddressSelect/AddressNation';
// import toaster from "../../utils/toaster";
//import LaTuaCard from "../../images/LaTuaCard_02.png"

export default function SignupLaTua(props) {
    const { register, handleSubmit, errors, setValue, watch } = useForm({
        mode: 'onBlur',
    });

    const [birthDate, setBirthdate] = useState();
    const [birthCountyId, setBirthCountyId] = useState();
    const [birthCityId, setBirthCityId] = useState();
    const [birthNationId, setBirthNationId] = useState();
    const [foreignNation, setForeignNation] = useState();

    useEffect(() => {
        register(
            { name: "birthDate" },
            { required: "Campo obbligatorio" },
        )
        register(
            { name: "birthCountyId" },
            props.type === "check" ?
                { required: "Campo obbligatorio" } : null
        )
        register(
            { name: "birthCityId" },
            props.type === "check" ?
                { required: "Campo obbligatorio" } : null
        )
        register(
            { name: "birthNationId" },
            props.type === "check" ?
                { required: "Campo obbligatorio" } : null
        )
        setValue("birthDate", birthDate);
        if (props.type === "check") {
            register({ name: "birthCity" })
            register({ name: "birthCounty" })
            register({ name: "birthNation" })
            setValue("birthCountyId", birthCountyId);
            setValue("birthCityId", birthCityId);
            setValue("birthNationId", birthNationId);
        }
    }, [register, birthDate, birthCountyId, birthCityId, birthNationId, props.type, setValue])

    const handleChange = date => {
        setValue("birthDate", date);
        setBirthdate(date);
        if (date) {
            errors.birthDate = {};
            errors.birthDate.message = "";
        } else {
            errors.birthDate = {};
            errors.birthDate.message = "Campo obbligatorio";
        }
    }

    const setNation = item => {
        setValue("birthNationId", item.id);
        setValue("birthNation", item.name);
        setBirthNationId(item.id);
        setForeignNation(item.foreignNation);
        if (item.foreignNation) {
            setValue("birthCityId", item.name);
            setBirthCityId(item.name);
        }
    }

    const setCounty = item => {
        setValue("birthCountyId", item.id);
        setValue("birthCounty", item.name);
        setBirthCountyId(item.id);
    }

    const setCity = item => {
        if (item.foreignCity) {
            setValue("birthCityId", item.name);
            setBirthCityId(item.name);
        } else {
            setValue("birthCityId", item.id);
            setBirthCityId(item.id);
        }
        setValue("birthCity", item.name);
    }

    const onSubmit = data => {
        props.signupCheck(data)
    }

    return (
        <Translation>
            {t =>
                <div className="signup-content">
                    <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="new-password"
                        className={`needs-validation ${errors ? "had-validation" : ""}`}>
                        <div className="signup-card">
                            <Row>
                                <Col md={6} className="form-group">
                                    <ValidateInput
                                        name="firstName"
                                        label="Nome*"
                                        type="text"
                                        watch={watch("firstName")}
                                        value={props.user && props.user.firstName}
                                        register={register({
                                            required: "Campo obbligatorio",
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.firstName && errors.firstName.message}
                                    </div>
                                </Col>
                                <Col md={6} className="form-group">
                                    <ValidateInput
                                        name="lastName"
                                        label="Cognome*"
                                        type="text"
                                        watch={watch("lastName")}
                                        value={props.user && props.user.lastName}
                                        register={register({
                                            required: "Campo obbligatorio",
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.lastName && errors.lastName.message}
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{ alignItems: "baseline" }}>
                                {props.type === "fetch" &&
                                    <Col md={6} className="form-group">
                                        <ValidateInput
                                            name="cardNumber"
                                            label="N° carta fedeltà*"
                                            type="text"
                                            watch={watch("cardNumber")}
                                            nospace={true}
                                            register={register({
                                                required: "Campo obbligatorio",
                                                pattern: {
                                                    // value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                    message: "Carta non valida"
                                                }
                                            })}
                                        />
                                        <div className="form-control-invalid">
                                            {errors.cardNumber && errors.cardNumber.message}
                                        </div>
                                        <div className="card-alert">
                                            <Alert />
                                            <span>{t("signup.card.alert")}</span>
                                        </div>
                                    </Col>
                                }
                                {props.type === "check" &&
                                    <Col md={6} className="form-group">
                                        <div className="d-flex">
                                            <div className="gender">Sesso*</div>
                                            <div className="radio-button">
                                                <div className="custom-control custom-radio">
                                                    <input type="radio" className="custom-control-input"
                                                        id="male"
                                                        name="gender"
                                                        value="MALE"
                                                        ref={register({ required: "Campo obbligatorio", })}
                                                    />
                                                    <label className="custom-control-label" htmlFor="male">
                                                        Uomo
                                                </label>
                                                </div>
                                            </div>
                                            <div className="radio-button">
                                                <div className="custom-control custom-radio">
                                                    <input type="radio" className="custom-control-input"
                                                        id="female"
                                                        name="gender"
                                                        value="FEMALE"
                                                        ref={register({ required: "Campo obbligatorio", })}
                                                    />
                                                    <label className="custom-control-label" htmlFor="female">
                                                        Donna
                                                </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-control-invalid">
                                            {errors.gender && errors.gender.message}
                                        </div>
                                    </Col>
                                }
                                <Col md={6} className="form-group">
                                    <SignupDatepicker
                                        label="Data di nascita*"
                                        onChange={handleChange}
                                        value={birthDate}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.birthDate && errors.birthDate.message}
                                    </div>
                                </Col>
                            </Row>
                            {props.type === "check" &&
                                <Row>
                                    <Col md={6} className="form-group">
                                        <AddressNation
                                            placeholder="Nazione di nascita*"
                                            setNation={setNation}
                                        />
                                        <div className="form-control-invalid">
                                            {errors.birthNationId && errors.birthNationId.message}
                                        </div>
                                    </Col>
                                    {birthNationId &&
                                        <Col md={6} className="form-group">
                                            <AddressCounty
                                                placeholder="Provincia di nascita*"
                                                setCounty={setCounty}
                                                nationId={birthNationId}
                                                foreignNation={foreignNation}
                                            />
                                            <div className="form-control-invalid">
                                                {errors.birthCountyId && errors.birthCountyId.message}
                                            </div>
                                        </Col>
                                    }
                                </Row>
                            }
                            {props.type === "check" &&
                                <Row>
                                    {birthNationId && birthCountyId && !foreignNation &&
                                        <Col md={6} className="form-group">
                                            <AddressCity
                                                placeholder="Comune di nascita*"
                                                setCity={setCity}
                                                countyId={birthCountyId}
                                                nationId={birthNationId}
                                            />
                                            <div className="form-control-invalid">
                                                {errors.birthCityId && errors.birthCityId.message}
                                            </div>
                                        </Col>
                                    }
                                </Row>
                            }
                        </div>
                        <div className="signup-actions text-center">
                            <button className="cta" type="submit">
                                <span>{t("signup.nextStep")}</span>
                            </button>
                        </div>
                    </form>
                </div>
            }
        </Translation>
    )
}
