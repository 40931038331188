import React from "react"
import CustomInput from "../../../../components/UI/Input/CustomInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Translation } from "react-i18next";
import AddressPopup from "../AddressPopup/AddressPopup";

const AddressCard = ({ address, index, action, deleteAddress }) => (
    <Translation>
        {t =>
            <div className="profile-addresses-card">
                <div className="address-header">
                    <div className="address-title">
                        {address.fromRemote ? "Indirizzo di Registrazione" : `Indirizzo ${index + 1}`}
                    </div>
                    {/* {address.fromRemote &&
                        <button className="update-button outline" disabled={true}>
                            <FontAwesomeIcon icon="edit" />
                            <span>Modifica</span>
                        </button>
                    } */}
                    {!address.fromRemote &&
                        <AddressPopup
                            address={address}
                            action={action}
                            deleteAddress={deleteAddress}
                            title={t("addresses.update")}
                            button={t("save")}
                            isUpdate={true}
                            trigger={
                                <button className="update-button outline">
                                    <FontAwesomeIcon icon="edit" />
                                    <span>Modifica</span>
                                </button>
                            }
                        />
                    }
                </div>
                <div className="address-info">
                    <div className="row address-row">
                        <div className="col-4">
                            <CustomInput
                                label="Nazione*"
                                type="text"
                                value={address && address.nation}
                                disabled
                            />
                        </div>
                        <div className="col-4">
                            <CustomInput
                                label="Città*"
                                type="text"
                                value={address && address.city}
                                disabled
                            />
                        </div>
                        <div className="col-4">
                            <CustomInput
                                label="Provincia*"
                                type="text"
                                value={address && address.county}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="row address-row">
                        <div className="col-sm-4">
                            <CustomInput
                                label="Cap*"
                                type="text"
                                value={address && address.zip}
                                disabled
                            />
                        </div>
                        <div className="col-8 col-sm-6">
                            <CustomInput
                                label="Indirizzo*"
                                type="text"
                                value={address && address.address}
                                disabled
                            />
                        </div>
                        <div className="col-4 col-sm-2 col-2">
                            <CustomInput
                                label="Civico*"
                                type="text"
                                value={address && address.streetNumber}
                                disabled
                            />
                        </div>
                    </div>
                </div>
            </div>
        }
    </Translation>
);

export default AddressCard;