import React from "react"
import { toast } from 'react-toastify';

toast.configure({
    autoClose: 3000,
    position: "top-right",
    hideProgressBar: true,
    newestOnTop: true,
    pauseOnHover: false
  });

const toaster = {
    success, error, info, warning, standard
}

function error(message) {
    toast.error(message.toString());
}

function success(message) {
    toast.success(message.toString());
}

function info(message) {
    toast.info(message.toString());
}

function warning(message) {
    toast.warning(message.toString());
}

function standard(message) {
    toast(message.toString(), {
        className: "toast-standard",
        closeButton: 
        <button type="button" className="close toast-close outline">
            <span aria-hidden="true" className="toast-close">&times;</span>
        </button>            
    });
}

export default toaster;