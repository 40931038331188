import React from "react";
import { Route, Switch } from 'react-router-dom';
import Row from "react-bootstrap/Row"
import CheckoutHeader from "./CheckoutHeader/CheckoutHeader";
import Disponibility from "./Disponibility/Disponibility";
import UserData from "./UserData/UserData";
import Confirm from "./Confirm/Confirm";
import { Translation } from 'react-i18next';
import { connect } from "react-redux"
import * as actionCreators from "../../redux/store/actions/index"
import toaster from "../../utils/toaster";

class Checkout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            day: this.props.checkoutInfo ? this.props.checkoutInfo.day : null,
            timeSlotId: this.props.checkoutInfo ? this.props.checkoutInfo.timeSlotId : null,

            profileEmail: this.props.user.email,
            profileMobilePhone: this.props.user.mobilePhone,
            profileFirstName: this.props.user.firstName,
            profileLastName: this.props.user.lastName,
            profileCard: "",
            notes: null,
            invoice: false,
            billingAddressId: null,
            billingAddress: null,
            enableProductsReplacement: null,
            paymentMethod: null
        };
        console.log(this.props.checkoutInfo);
    }

    shouldComponentUpdate(nextProps) {
        if (this.props.checkoutInfo && nextProps.checkoutInfo === null) return false
        else return true
    }

    componentDidMount() {
        if (this.props.checkoutInfo) this.props.saveCheckoutInfo(null)
    }

    selectDay = (day) => {
        if (this.state.day) {
            if (document.getElementById(this.state.day)) {
                document.getElementById(this.state.day).classList.remove("disponibility-button-selected");
            }
        }
        this.setState({ day: day, timeSlotId: null })
        document.getElementById(day).classList.add("disponibility-button-selected");
    }


    selectInvoice = (e) => {
        if (e.target.checked === false) {
            this.setState({ invoice: e.target.checked, billingAddressId: null })
        } else {
            this.setState({ invoice: e.target.checked })
        }
    }

    enableReplacement = (value) => {
        this.setState({ enableProductsReplacement: value })
    }

    selectTimeslot = (timeSlotId) => {
        if (this.state.timeSlotId) {
            if (document.getElementById(this.state.timeSlotId)) {
                document.getElementById(this.state.timeSlotId).classList.remove("pill-active");
            }
        }
        this.setState({ timeSlotId: timeSlotId })
        document.getElementById(timeSlotId).classList.add("pill-active");
    }

    selectAddress = (address) => {
        this.setState({
            billingAddressId: address.id,
            billingAddress: address
        })
    }

    selectProfileInfo = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    setPaymentMethod = (value) => {
        this.setState({ paymentMethod: value })
    }

    freeze = (cartId, integrationNotes) => {
        console.log(this.props.checkoutInfo);
        const { invoice, billingAddress } = this.state;
        const { overlay } = this.props;
        if (invoice) {
            if (billingAddress &&
                billingAddress.vatCode &&
                billingAddress.companyName &&
                billingAddress.address &&
                billingAddress.streetNumber &&
                billingAddress.zip &&
                billingAddress.city &&
                billingAddress.county &&
                billingAddress.phone) {
                this.props.freeze(this.state, cartId, this.state.paymentMethod);
                overlay(true);
                setTimeout(() => {
                    overlay(false)
                }, 3000);
            } else {
                toaster.standard("Indirizzo di fatturazione incompleto!") // todo, please check why i18n not working.
            }
        } else {
            this.props.freeze(this.state, cartId, this.state.paymentMethod, integrationNotes);
            overlay(true);
            setTimeout(() => {
                overlay(false)
            }, 3000);
        }
    }

    render() {
        const { cart, user } = this.props;
        return (
            <Translation>
                {t =>
                    <div className="checkout-container">
                        <div className="container-fluid">
                            <CheckoutHeader data={this.state} />
                            <Row className="g-padding">
                                <div className="checkout-view mx-auto">
                                    <Switch>
                                        <Route exact path='/checkout'
                                            render={() => <Disponibility
                                                selectDay={this.selectDay}
                                                selectTimeslot={this.selectTimeslot}
                                                day={this.state.day}
                                                timeSlotId={this.state.timeSlotId}
                                                cart={cart}
                                            />} />
                                        <Route exact path='/checkout/data'
                                            render={() => <UserData
                                                selectProfileInfo={this.selectProfileInfo}
                                                profileEmail={this.state.profileEmail}
                                                profileMobilePhone={this.state.profileMobilePhone}
                                                profileFirstName={this.state.profileFirstName}
                                                profileLastName={this.state.profileLastName}
                                                profileCard={this.state.profileCard}
                                                billingAddressId={this.state.billingAddressId}
                                                selectAddress={this.selectAddress}
                                                selectInvoice={this.selectInvoice}
                                                invoice={this.state.invoice}
                                                timeSlotId={this.state.timeSlotId}
                                                cart={cart}
                                                freeze={this.freeze}
                                                billingAddress={this.state.billingAddress}
                                                enableProductsReplacement={this.state.enableProductsReplacement}
                                                enableReplacement={this.enableReplacement}
                                                paymentMethod={this.state.paymentMethod}
                                                setPaymentMethod={this.setPaymentMethod}
                                                user={user}
                                            />} />
                                        <Route exact path='/checkout/confirm'
                                            render={() => <Confirm cart={cart} />}
                                        />
                                    </Switch>
                                </div>
                            </Row>
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        cart: state.cart.cart,
        checkoutInfo: state.cart.checkoutInfo
    };
}

const mapDispatchToProps = dispatch => {
    return {
        freeze: (data, cartId, paymentMode, integrationNotes) => dispatch(actionCreators.freeze(data, cartId, paymentMode, integrationNotes)),
        overlay: (isActive) => dispatch(actionCreators.overlay(isActive)),
        saveCheckoutInfo: (data) => dispatch(actionCreators.saveCheckoutInfo(data))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);