import React from "react"
import { useForm } from 'react-hook-form'
import { Translation } from 'react-i18next';
import Loader from 'react-loader-spinner'

import "../signup.styles.scss";

function SignupStep4(props) {
    const { register, handleSubmit, errors } = useForm({
        mode: 'onBlur',
    });

    const onSubmit = data => {
        props.signup(data);
    }

    const { data, isLoading } = props;

    return (
        <Translation>
            {t =>
                <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off"
                    className={`signup needs-validation ${errors ? "had-validation" : ""}`}>
                    <div className="signup-content">
                        <div className="signup-card">
                            {/* <span className="card-title">{t("signup.conditionsOfServiceAndPrivacy")}</span>
                            <hr className="hr" /> */}
                            <div className="card-chapter">{t("Consensi A")}</div>
                            <div className="custom-control custom-checkbox">
                                <input className="custom-control-input" type="checkbox" id="accept1" name="accept1"
                                    defaultChecked={data && data.accept1}
                                    ref={register({
                                        required: "Campo obbligatorio"
                                    })}
                                />
                                <label className="custom-control-label" htmlFor="accept1">
                                    {/* {t("signup.acceptTermsAndConditions")}* */}
                                    Dichiaro di aver letto e di accettare integralmente
                                    i <a href="https://laspesaacasa.siconte.it/resources/01/CONDIZIONI_DI_VENDITA_ver_23_02_2021.pdf"
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        Termini e le condizioni
                                    </a> di servizio*
                                </label>
                                <div className="form-control-invalid">
                                    {errors.accept1 && errors.accept1.message}
                                </div>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input className="custom-control-input" type="checkbox" id="accept2" name="accept2"
                                    defaultChecked={data && data.accept2}
                                    ref={register({
                                        required: "Campo obbligatorio"
                                    })}
                                />
                                <label className="custom-control-label" htmlFor="accept2">
                                    {/* {t("signup.acceptPrivacyPolicy")}* */}
                                    Dichiaro di aver letto
                                    la <a href="https://laspesaacasa.siconte.it/resources/01/MCG_CEDI_Info_Spesa_a_casa_v_1_Rev_2021_02_03.pdf"
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        Privacy Policy
                                    </a> indicata nella informativa per la registrazione al servizio laspesaacasa.siconte.it*
                                </label>
                                <div className="form-control-invalid">
                                    {errors.accept2 && errors.accept2.message}
                                </div>
                            </div>
                            <div className="card-chapter">{t("Consensi B")}</div>
                            <div className="custom-control custom-checkbox">
                                <input className="custom-control-input" type="checkbox" id="accept3" name="accept3"
                                    defaultChecked={data && data.accept3}
                                    ref={register
                                        // ({ required: "Campo obbligatorio" })
                                    }
                                />
                                <label className="custom-control-label" htmlFor="accept3">
                                    {t("signup.acceptMarketing")}
                                </label>
                                <div className="form-control-invalid">
                                    {errors.accept3 && errors.accept3.message}
                                </div>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input className="custom-control-input" type="checkbox" id="accept4" name="accept4"
                                    defaultChecked={data && data.accept4}
                                    ref={register
                                        // ({ required: "Campo obbligatorio" })
                                    }
                                />
                                <label className="custom-control-label" htmlFor="accept4">
                                    {t("signup.acceptProfiling")}
                                </label>
                                <div className="form-control-invalid">
                                    {errors.accept4 && errors.accept4.message}
                                </div>
                            </div>
                        </div>
                        <div className="signup-actions text-center">
                            <button className="cta" type="submit" disabled={isLoading}>
                                {isLoading ?
                                    <Loader
                                        type="ThreeDots"
                                        color="#ffffff"
                                        height={50}
                                        width={50}
                                    /> :
                                    <span>{t("signup.register")}</span>
                                }
                            </button>
                        </div>
                    </div>
                </form>
            }
        </Translation >
    );
}

export default SignupStep4;